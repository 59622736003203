'use client';
import React, {FC, useEffect, useState} from 'react';
import './MainPage.scss';
import Header from '../../components/Header/Header';
import Preview from '../../components/Preview/Preview';
import AboutUs from '../../components/AboutUs/AboutUs';
import Suggestions from '../../components/Suggestion/Suggestion';
import Benefits from '../../components/Benefits/Benefits';
import Contacts from '../../components/Contacts/Contacts';
import MobileHeader from '../../components/MobileHeader/MobileHeader';
import useResponsiveHeader from '../../components/useResponsiveHeader/useResponsiveHeader';
import {useLocation} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {AppDispatch, RootState} from '../../store/store';
import {useSelector} from 'react-redux';
import ParamParser from '../../helper/ParamParser';
import {getMainInfo} from '../../store/page-main/actions';
import SEO from '../../SEO/SEO';

const Home: FC = () => {
  const {isMobileHeader, isSmallScreen, showHeader, handleClick} =
    useResponsiveHeader();
  const parserParam = ParamParser();

  const {pathname} = useLocation();
  useEffect(() => {
    document?.querySelector('body')?.scrollTo(0, 0);
  }, [pathname]);

  const params = useSelector((state: RootState) => state.language.params);
  const bg = useSelector((state: RootState) => state.header);
  const dispatch: AppDispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(getMainInfo(params));
  // }, []);
  useEffect(() => {
    dispatch(getMainInfo(params));
  }, [params]);

  const seo = useSelector((state: RootState) => state.metadata);
  const {title, description, favicon} = seo;

  return (
    <div className={'home'}>
      <SEO title={title} description={description} />
      <div className={'home__wrap'}>
        {isSmallScreen && (
          <MobileHeader isActive={isMobileHeader} handelClick={handleClick} />
        )}
        <div
          className={'home__header'}
          style={{
            background: `center/cover no-repeat url(${bg?.image})`,
          }}
        >
          {showHeader && <Header />}
          <Preview />
        </div>
        <div className='padding-space bg-dark '>
          <AboutUs />
        </div>
        <div className='padding-space bg-light'>
          <Suggestions />
        </div>
        <div className='padding-space bg-dark'>
          <Benefits />
        </div>
        <div className='padding-space bg-light'>
          <Contacts />
        </div>
      </div>
    </div>
  );
};
export default Home;
