import React, {FC, useEffect, useState} from 'react';
import './SelectLanguage.scss';
import {useSelector} from 'react-redux';
import {AppDispatch, RootState} from '../../store/store';
import {useDispatch} from 'react-redux';
import {setLanguages} from '../../store/languages/actions';
import {useNavigate} from 'react-router-dom';
import {getHeader} from '../../store/getHeader/actions';
import {params_en, params_he} from '../../API/get-params';

const SelectLanguage: FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [language, setLanguage] = useState({
    current: '',
    optional: '',
  });
  useEffect(() => {
    let lang = localStorage.getItem('language');
    const parsedLang = JSON.parse(lang as string);

    const initialLanguage = {
      current: parsedLang?.current || 'en',
      optional: parsedLang?.optional || 'עב',
    };
    setLanguage(initialLanguage);
  }, []);

  const changeLanguage = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    const {currentTarget} = e;

    const newLanguage = {
      current: currentTarget.textContent as string,
      optional: currentTarget.textContent === 'EN' ? 'עב' : 'EN',
    };

    localStorage.setItem('language', JSON.stringify(newLanguage));
    setLanguage(newLanguage);
    setIsOpen(!isOpen);

    if (
      window.location.pathname ===
      `/${currentTarget.textContent?.toLocaleLowerCase()}`
    )
      return;
    else {
      dispatch(
        getHeader(currentTarget.textContent === 'עב' ? params_he : params_en),
      );
      navigate(
        `/${
          currentTarget.textContent == 'עב' ? 'he' : 'en'
        }${window.location.pathname.substring(3)}`,
      );
    }
  };

  return (
    <div className='select-language'>
      <div className='select-language__list'>
        <button
          className={`select-language__button _current ${isOpen ? 'open' : ''}`}
          onClick={changeLanguage}
        >
          {language.current}
        </button>
        {isOpen && (
          <button className='select-language__button' onClick={changeLanguage}>
            {language.optional}
          </button>
        )}
      </div>
    </div>
  );
};

export default SelectLanguage;
