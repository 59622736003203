import {useState, useEffect} from 'react';

const useResponsiveHeader = () => {
  const [isMobileHeader, setIsMobileHeader] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(true);
  const [showHeader, setShowHeader] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (!isSmallScreen) {
      setShowHeader(true);
    } else {
      setShowHeader(false);
    }
  }, [isSmallScreen]);

  useEffect(() => {
    let html = document.querySelector('html');
    if ((showHeader && !isSmallScreen) || (!showHeader && isSmallScreen)) {
      html?.classList.remove('scroll-hidden');
    } else {
      html?.classList.add('scroll-hidden');
    }
  }, [showHeader, isSmallScreen]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleClick = () => {
    setIsMobileHeader(!isMobileHeader);
    setShowHeader(!showHeader);
  };

  return {
    isMobileHeader,
    isSmallScreen,
    showHeader,
    handleClick,
  };
};

export default useResponsiveHeader;
