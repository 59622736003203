import {ParamsProps} from '../../API/get-params';
import {setMainInfo} from '../../API/API';
import {AppDispatch} from '../store';
import {DataFooterProp} from './type';

export const GET_FOOTER = 'getFooter/GET_FOOTER';

export type FooterProp = {
  type: typeof GET_FOOTER;
  data: DataFooterProp;
};
export type ActionsType = FooterProp;

export const getFooter = (params: ParamsProps) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await setMainInfo(params, 'footer');
      const data = await response.data;
      dispatch({type: GET_FOOTER, data: data});
    } catch (e: any) {}
  };
};
