'use client';
import MobileHeader from '../../components/MobileHeader/MobileHeader';
import './NewCases.scss';
import Header from '../../components/Header/Header';
import {useEffect, useState} from 'react';
import useResponsiveHeader from '../../components/useResponsiveHeader/useResponsiveHeader';
import Form from '../../components/Form/Form';
import {useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {AppDispatch, RootState} from '../../store/store';
import ParamParser from '../../helper/ParamParser';
import SEO from '../../SEO/SEO';
import {useDispatch} from 'react-redux';
import {getMethod} from '../../store/message-post/actions';

export default function NewCases() {
  const {pathname} = useLocation();
  const parserParam = ParamParser();
  const newCase = useSelector(
    (state: RootState) => state.language.currentLanguage.newCase,
  );

  useEffect(() => {
    document?.querySelector('body')?.scrollTo(0, 0);
  }, [pathname]);
  const [isChecked, setIsChecked] = useState(true);
  const getSelectedLabelText = () => {
    return isChecked ? newCase.phoneLabel : newCase.emailLabel;
  };
  const dispatch: AppDispatch = useDispatch();

  const handleRadioChange = (event: any) => {
    const method = event.target.getAttribute('data-method');
    dispatch(getMethod(method));
    setIsChecked(event.target.id === 'phone');
  };
  const {isMobileHeader, isSmallScreen, showHeader, handleClick} =
    useResponsiveHeader();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={'case_page'}>
      <SEO title={newCase.title} description={newCase.desc} />
      {isSmallScreen && (
        <MobileHeader isActive={isMobileHeader} handelClick={handleClick} />
      )}
      <div className='bg-dark'>{showHeader && <Header />}</div>
      <div className='bg-different'>
        <div className={`${'case_page__wrap'} wrap`}>
          <div className={`${'case_page__row'}`}>
            <div className={`${'case_page__info'}`}>
              <p className={`${'case_page__title'}`}>
                {newCase.title}
                <span>{newCase.desc}</span>
              </p>
              <div className={`${'case_page__box'}`}>
                <p>{newCase.subTitle}</p>
                <form className={`${'case_page__form'}`} name='form-submit'>
                  <div>
                    <input
                      name='form'
                      type='radio'
                      id='phone'
                      className={`${'case_page__input'}`}
                      checked={isChecked}
                      data-method='phone'
                      onChange={handleRadioChange}
                    />
                    <label htmlFor='phone' className={`${'case_page__label'}`}>
                      {newCase.phoneLabel}
                    </label>
                  </div>
                  <div>
                    <input
                      type='radio'
                      name='form'
                      id='email'
                      data-method='email'
                      checked={!isChecked}
                      onChange={handleRadioChange}
                      className={`${'case_page__input'}`}
                    />
                    <label htmlFor='email' className={`${'case_page__label'}`}>
                      {newCase.emailLabel}
                    </label>
                  </div>
                </form>
              </div>
            </div>
            <div className={`${'case_page__form_box'}`}>
              <Form />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
