import React, {FC, useEffect} from 'react';
import './Footer.scss';
import {Link} from 'react-router-dom';
// import {FOOTER_PRODUCTS, MENU_ITEMS} from '../../constants/constants_en';
import {useSelector} from 'react-redux';
import {AppDispatch, RootState} from '../../store/store';
import {NavLinkProps} from '../Header/Navigation';
import Logo from '../Logo/Logo';
import {params_en} from '../../API/get-params';
import {useDispatch} from 'react-redux';
import {getFooter} from '../../store/getFooter/actions';
import {getProductsInfo} from '../../store/page-products/actions';

const Footer: FC = () => {
  // lang.selectedLanguage)
  const state = useSelector(
    (state: RootState) => state.language.selectedLanguage,
  );
  const info = useSelector((state: RootState) => state.footer);
  const footer = useSelector(
    (state: RootState) => state.language.currentLanguage.footer,
  );
  const params = useSelector((state: RootState) => state.language.params);
  const product_cards = useSelector(
    (state: RootState) => state?.all_products_page?.product_cards,
  );

  const dispatch: AppDispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(getFooter(params));
  //   dispatch(getProductsInfo(params));
  // }, []);

  useEffect(() => {
    dispatch(getFooter(params));
    dispatch(getProductsInfo(params));
  }, [params]);

  return (
    <div className='footer'>
      <div className='footer__wrap wrap'>
        <Logo className='footer__logo' />
        <div className='footer__row'>
          <div className='footer__navigate'>
            <nav className='footer__nav'>
              <div>
                <p className='footer__label large-font'>{footer.titleA}</p>
                <ul className='footer__list'>
                  {info?.navigation?.map((item: any, index: any) => {
                    return (
                      <li key={index}>
                        <Link
                          to={`/${state}/${item.slug.toLocaleLowerCase()}`}
                          className='footer__link'
                        >
                          {item.name}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </nav>
            <div className='footer__nav'>
              <div>
                <p className='footer__label large-font'>{footer.titleB}</p>
                <ul className='footer__list'>
                  {product_cards?.map((item: any, index: any) => {
                    return (
                      <li key={index}>
                        <Link
                          to={`${state}/products/${item.slug.toLocaleLowerCase()}`}
                          className='footer__link'
                        >
                          {item.title}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
          <div className='footer__info'>
            <div className='footer__box'>
              <p className='footer__label large-font _contacts'>
                {footer.titleC}
              </p>
              <p className='footer__desc small-font'>
                <span>
                  {footer.phone}
                  <br />
                  <a
                    href={`tel:${
                      info?.phone_number &&
                      info?.phone_number.replace(/\s+/g, '').replace(/\+/g, '')
                    }`}
                    dir='ltr'
                  >
                    {info?.phone_number}
                  </a>
                  <br />
                  <br />
                  {info?.website} <br />
                  <br />
                  <a href={`mailto:${info?.email}`} dir='ltr'>
                    {info?.email}
                  </a>
                </span>
              </p>
            </div>
            <div className='footer__box'>
              <p className='footer__label large-font _address'>
                {footer.titleD}
              </p>
              <p className='footer__desc small-font'>
                <span>
                  {info?.address}
                  <br />
                  {/* 2165379 */}
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className='footer__additional-info'>
          <p className='footer__copy'>{footer.copy}</p>
          <ul className='footer__additional-link'>
            <li>
              <Link to={`/`} className='footer__link'>
                {footer.cookie}
              </Link>
            </li>
            <li>
              <Link to={`/`} className='footer__link'>
                {footer.legal}
              </Link>
            </li>
            <li>
              <Link to={`/`} className='footer__link'>
                {footer.policy}
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Footer;
