import React, {FC} from 'react';
import Card from './Card/Card';

import './Benefits.scss';
import {useSelector} from 'react-redux';
import {RootState} from '../../store/store';
import {BenefitsCardType, CardType} from '../../constants/type';
import ClientCard from '../ClientCard/ClientCard';
import {useInView} from 'react-intersection-observer';

const Benefits: FC = () => {
  const {ref, inView} = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });
  const benefits = useSelector(
    (state: RootState) => state.language.currentLanguage.benefits,
  );
  const info = useSelector((state: RootState) => state.main_page.why_us);
  const previous_clients = useSelector(
    (state: RootState) => state.main_page?.previous_clients,
  );

  return (
    <div className='benefits' ref={ref}>
      {inView && (
        <div className='benefits__wrap wrap'>
          <h2 className='h2 benefits__title'>{info?.title}</h2>
          <p className='benefits__desc'>{info?.description}</p>
          <div className='benefits__flex'>
            <div
              className='benefits__img'
              style={{background: `left/contain no-repeat url(${info?.image})`}}
            ></div>
            <div className='benefits__row'>
              {info?.item?.map((item: any, index: any) => {
                return (
                  <div key={index} className='benefits__card'>
                    <Card item={item} />
                  </div>
                );
              })}
            </div>
          </div>
          <div className='benefits__clients'>
            <h2 className='h2 benefits__title'>{previous_clients?.title}</h2>
            <p className='benefits__desc'>{previous_clients?.description}</p>
            <div className='benefits__client_row'>
              {previous_clients?.client?.map((card: CardType, i: any) => (
                <div className='benefits__client_card' key={i}>
                  <ClientCard card={card} />
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Benefits;
