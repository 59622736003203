export const SET_LANGUAGE = 'languages/SET_LANGUAGE';

export interface SetLanguageAction {
  type: typeof SET_LANGUAGE;
  payload: string;
}

export const setLanguages = (language: string): SetLanguageAction => ({
  type: SET_LANGUAGE,
  payload: language,
});
