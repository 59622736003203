import axios from 'axios';
import {FormDataPops, ParamsProps} from './get-params';
const cache = {};
export function setMainInfo(body: ParamsProps, param?: string) {
  return axios.get(
    `https://back.wesmart.org.il:8000/api/v1/main_page/${param}`,
    body,
  );
}

export function setAboutInfo(body: ParamsProps) {
  return axios.get(`https://back.wesmart.org.il:8000/api/v1/about-page/`, body);
}
export function setContactInfo(body: ParamsProps) {
  return axios.get(
    `https://back.wesmart.org.il:8000/api/v1/contact-page/`,
    body,
  );
}
export function setProductInfo(body: ParamsProps, param?: string) {
  return axios.get(
    `https://back.wesmart.org.il:8000/api/v1/product-page/${param}`,
    body,
  );
}
export function setSolutionsInfo(body: ParamsProps, slug: string) {
  return axios.get(
    `https://back.wesmart.org.il:8000/api/v1/solution-page/${slug}`,
    body,
  );
}

export function sendMessageToServer(body: FormDataPops) {
  return axios.post(
    `https://back.wesmart.org.il:8000/api/v1/contact-page/create/`,
    body,
  );
}
