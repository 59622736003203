import {GET_CONTACT} from './actions';
import {DataHeaderProp} from './type';
export type ContactProps = {
  type: typeof GET_CONTACT;
  data: DataHeaderProp;
};
export type ActionsType = ContactProps;

const initialState = {};

export const ContactReducer = (state = initialState, action: ActionsType) => {
  switch (action.type) {
    case GET_CONTACT: {
      return action.data;
    }
    default:
      return state;
  }
};
