import {GET_META_DATA} from './actions';

export type MetaDataProps = {
  type: typeof GET_META_DATA;
  data: any;
};
export type ActionsType = MetaDataProps;

const initialState = {};

export const MetaDataReducer = (state = initialState, action: ActionsType) => {
  switch (action.type) {
    case GET_META_DATA: {
      return action.data;
    }
    default:
      return state;
  }
};
