import React, {FC, ReactNode} from 'react';
import './ContactCard.scss';
import {useSelector} from 'react-redux';
import {RootState} from '../../store/store';

type ContactCardT = {
  card: any;
};
const ContactCard: FC<ContactCardT> = ({card}) => {
  const state = useSelector(
    (state: RootState) => state.language.selectedLanguage,
  );
  const startsWithDigit = /^\d/.test(card.button_url);
  return (
    <div className='contact-card'>
      <div className='contact-card__wrap'>
        <div
          className='contact-card__img'
          style={{background: `center/contain no-repeat url(${card.icon})`}}
        ></div>
        <div className='contact-card__box'>
          <p className='contact-card__title large-font'>{card.title}</p>
          <p className={`contact-card__desc`}>{card.description}</p>
          <a
            href={
              startsWithDigit
                ? `tel:${card.button_url}`
                : `/${state}/${card.button_url.toLocaleLowerCase()}`
            }
            className='button-orange contact-card__button'
            target='_blank'
          >
            {card.button_text}
          </a>
        </div>
      </div>
    </div>
  );
};

export default ContactCard;
