import React, {FC, useState} from 'react';
import Card, {CardItemType} from './Card/Card';
import './Suggestion.scss';
import {RootState} from '../../store/store';
import {useSelector} from 'react-redux';
import {useInView} from 'react-intersection-observer';

const Suggestions: FC = () => {
  const {ref, inView} = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });
  // const suggestions = useSelector(
  //   (state: RootState) => state.language.currentLanguage.suggestions,
  // );
  const info = useSelector(
    (state: RootState) => state?.main_page?.solution_block,
  );

  return (
    <div className='suggestion' ref={ref}>
      {inView && (
        <div className='suggestion__wrap wrap'>
          <h2 className='h2 suggestion__title'>{info?.title}</h2>
          <p className='suggestion__desc small-font '>{info?.description}</p>
          <div className='suggestion__row'>
            {info?.solution?.map((item: any, index: any) => {
              return (
                <div className='suggestion__card' key={index}>
                  <Card item={item} />
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default Suggestions;
