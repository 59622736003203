import {ParamsProps} from '../../API/get-params';
import {setContactInfo} from '../../API/API';
import {AppDispatch} from '../store';

export const GET_CONTACT_INFO = 'page-about/GET_ABOUT_INFO';

export type ContactPageProps = {
  type: typeof GET_CONTACT_INFO;
  data: any;
};
export type ActionsType = ContactPageProps;

export const getContactInfo = (params: ParamsProps) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await setContactInfo(params);
      const data = await response.data;
      dispatch({type: GET_CONTACT_INFO, data: data});
    } catch (e: any) {}
  };
};
