import React, {useEffect} from 'react';
import Header from '../../components/Header/Header';
import ProductCard from '../../components/ProductCard/ProductCard';
import './ProductsPage.scss';
import MobileHeader from '../../components/MobileHeader/MobileHeader';
import useResponsiveHeader from '../..//components/useResponsiveHeader/useResponsiveHeader';
import {useLocation, useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {AppDispatch, RootState} from '../../store/store';
import Contacts from '../../components/Contacts/Contacts';
import {useDispatch} from 'react-redux';
import {getProductsInfo} from '../../store/page-products/actions';
import ParamParser from '../../helper/ParamParser';
import SEO from '../../SEO/SEO';

export default function ProductsPage() {
  const {isMobileHeader, isSmallScreen, showHeader, handleClick} =
    useResponsiveHeader();
  const {pathname} = useLocation();
  const parserParam = ParamParser();
  useEffect(() => {
    document?.querySelector('body')?.scrollTo(0, 0);
  }, [pathname]);
  const dispatch: AppDispatch = useDispatch();
  const params = useSelector((state: RootState) => state.language.params);
  const info = useSelector((state: RootState) => state.all_products_page);

  // useEffect(() => {
  //   dispatch(getProductsInfo(params));
  // }, []);
  useEffect(() => {
    dispatch(getProductsInfo(params));
  }, [params]);
  return (
    <div className={'product_page'}>
      <SEO title={info?.title} description={info?.description} />

      <div className={'product_page__wrap'}>
        {isSmallScreen && (
          <MobileHeader isActive={isMobileHeader} handelClick={handleClick} />
        )}
        <div className={`bg-dark`}>
          <div className={'product_page__bg'}>
            <div className='wrap '>
              {showHeader && <Header />}
              <div className={'product_page__box'}>
                <h1 className={` ${'product_page__title'} h1`}>
                  {info?.title}
                </h1>
                <p className={'product_page__desc'}>
                  <span>{info?.description}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`${'product_page__section'} bg-light-blue padding-space-top padding-space-bottom`}
        >
          <div className='wrap'>
            <h2 className={'product_page__text'}>{info?.label}</h2>
          </div>
        </div>
        <div>
          {info?.product_cards?.map((card: any, index: any) => {
            return (
              <div className={'product_page__inner'} key={index}>
                <ProductCard
                  odd={index % 2 !== 0 ? false : true}
                  card={card}
                  isGeneralPage={true}
                />
              </div>
            );
          })}
        </div>
        <div className='padding-space'>
          <Contacts />
        </div>
      </div>
    </div>
  );
}
