import {ActionsType, GET_SOLUTIONS_INFO} from './actions';

const initialState = {};

export const SolutionsInfoReducer = (
  state = initialState,
  action: ActionsType,
) => {
  switch (action.type) {
    case GET_SOLUTIONS_INFO: {
      return action.data;
    }
    default:
      return state;
  }
};
