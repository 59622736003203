import {FormDataPops, ParamsProps} from '../../API/get-params';
import {sendMessageToServer, setAboutInfo} from '../../API/API';
import {AppDispatch} from '../store';

export const SEND_MESSAGE = 'message-post/SEND_MESSAGE';
export const GET_METHOD = 'message-post/GET_METHOD';

export type AboutPageProps = {
  type: typeof SEND_MESSAGE;
  data: any;
};
export type ActionsType =
  | {type: typeof SEND_MESSAGE; data: any}
  | {type: typeof GET_METHOD; payload: any};

export const sendMessage = (body: FormDataPops) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await sendMessageToServer(body);
      const data = await response.data;
      dispatch({type: SEND_MESSAGE, data: data});
    } catch (e: any) {}
  };
};

export const getMethod = (name: string) => ({
  type: GET_METHOD,
  payload: name,
});
