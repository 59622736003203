import React, {FC, ReactNode, useEffect} from 'react';
import ContactCard from '../../components/ContactCard/ContactCard';
import Header from '../../components/Header/Header';
import './ContactsPage.scss';
import MobileHeader from '../../components/MobileHeader/MobileHeader';
import useResponsiveHeader from '../../components/useResponsiveHeader/useResponsiveHeader';
import {useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {AppDispatch, RootState} from '../../store/store';
import ParamParser from '../../helper/ParamParser';
import {useDispatch} from 'react-redux';
import {getContactInfo} from '../../store/page-contact/actions';
import SEO from '../../SEO/SEO';

const Contacts: FC = () => {
  const parserParam = ParamParser();
  const {isMobileHeader, isSmallScreen, showHeader, handleClick} =
    useResponsiveHeader();
  const {pathname} = useLocation();

  const params = useSelector((state: RootState) => state.language.params);
  const info = useSelector((state: RootState) => state.contact_page);
  const dispatch: AppDispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(getContactInfo(params));
  // }, []);
  useEffect(() => {
    dispatch(getContactInfo(params));
  }, [params]);
  useEffect(() => {
    document?.querySelector('body')?.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div className={'contact-page'}>
      <SEO title={info?.title} description={info?.description} />
      {isSmallScreen && (
        <MobileHeader isActive={isMobileHeader} handelClick={handleClick} />
      )}
      <div className='bg-dark'>{showHeader && <Header />}</div>
      <div className={'contact-page__wrap'}>
        <div className='wrap'>
          <h2 className={`h2 ${'contact-page__title'}`}> {info?.title}</h2>
          <p className={'contact-page__desc'}>{info?.description}</p>
          <div className={'contact-page__row'}>
            {info?.cards?.map((card: any, i: any) => {
              return <ContactCard key={i} card={card} />;
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contacts;
