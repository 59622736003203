import {ActionsType, GET_METHOD, SEND_MESSAGE} from './actions';

const initialState = {
  payload: 'phone',
  data: {},
};

export const MessageReducer = (state = initialState, action: ActionsType) => {
  switch (action.type) {
    case SEND_MESSAGE: {
      const clone = structuredClone(state);
      clone.data = action.data;
      return clone;
    }
    case GET_METHOD: {
      const clone = structuredClone(state);
      clone.payload = action.payload;
      return clone;
    }
    default:
      return state;
  }
};
