import {ParamsProps} from './../../API/get-params';
import {setMainInfo} from '../../API/API';
import {AppDispatch} from '../store';
import {DataHeaderProp} from './type';

export const GET_HEADER = 'getHeader/GET_HEADER';

export type HeaderProp = {
  type: typeof GET_HEADER;
  data: DataHeaderProp;
};
export type ActionsType = HeaderProp;

export const getHeader = (params: ParamsProps) => {
  return async (dispatch: AppDispatch) => {
    try {
      // start of loading

      const response = await setMainInfo(params, 'header');
      const data = await response.data;
      dispatch({type: GET_HEADER, data: data});

      // end of loading
      // dispatch(endOfLoading());

      // if (data.products.length === 0) {
      // dispatch(getIsError('No found'));
      // }
    } catch (e: any) {
      // dispatch(getIsError(e.message));
    }
  };
};
