import {InfoT} from './type';
import {CardT, CategoryT, ChatBotCardT} from '../types/types';
import img_1 from '../asserts/Logistic.png';
import sq_bg from '../asserts/Background-seq.png';
import img_2 from '../asserts/Security.png';
import img_3 from '../asserts/Smart-Cities.png';
import wq from '../asserts/interface-pad-lock-shield--combination-combo-lock-locked-padlock.svg';
import wq2 from '../asserts/interface-pad-lock-shield--combination-combo-lock-locked-padlock-secure-security-shield-square.svg';
import wq3 from '../asserts/interface-pad-lock-shield--combinatiod.svg';
import city_bg from '../asserts/Background-city.png';

import real from '../asserts/interface-pad.svg';
import lock from '../asserts/interface-pad-lock-shield.svg';
import square from '../asserts/interface-pad-lock-shield--combinationld-square.svg';
import client1 from '../asserts/logo2-cfsf-removebg-preview.png';
import client2 from '../asserts/ysb.png';
import client3 from '../asserts/logo2-cfs.png';
import phone from '../asserts/phone-icon.svg';
import box from '../asserts/box-icon.svg';
import chat from '../asserts/chat-icon.svg';
import product_img_1 from '../asserts/content-product_1.svg';
import product_img_2 from '../asserts/content-product_2.svg';
import product_img_3 from '../asserts/content-product_3.svg';
import product_img_4 from '../asserts/content-product_4.svg';
import product_img_5 from '../asserts/content-product_5.svg';

import icon_heart from '../asserts/icon-heart.svg';
import icon_interface from '../asserts/icon-interface.svg';

import icon_light from '../asserts/icon-scroll.svg';

import icon_shuffle from '../asserts/icon-shuffle.svg';
import icon_lock from '../asserts/icon-lock.svg';

type MenuItemsTypes = {
  label: string;
  link: string;
};

export const df_info_he: InfoT = {
  button_more: ' למד עוד',

  button_title: 'בקשת הדגמה',
  MENU_ITEMS: [
    {
      label: 'בית',
      link: '/',
    },
    {
      label: 'אודות',
      link: '/about',
    },
    {
      label: 'מוצרים',
      link: '/products',
    },
    {
      label: 'בלוג',
      link: '/blog',
    },
    {
      label: 'יצירת קשר',
      link: '/contacts',
    },
  ],
  safety: {
    DEFAULT_CARD: {
      odd: false,
      img: product_img_1,
      isGeneralPage: false,
      date: '01/05',
      name: 'מערכת לזיהוי נפילות בזמן אמת',
      title: '',
      desc: `הטכנולוגיה שפתחנו לזיהוי מהיר ומדויק של נפילות מספקת רשת ביטחון, המערכת של WeSmart מזהה באופן מדויק נפילות ויודעת להבחין ביניהן לבין פעילויות רגילות, כך נמנעות אזעקות שווא מיותרות.`,
    },
    title: `הפתרונות שלנו`,
    desc: [
      `הפתרונות שלנו מספקים לך את השקט הנפשי והביטחון שאתה זקוק להם כדי להתמקד בדברים החשובים באמת
      .בוא לגלות מגוון רחב פתרונות מתואמות לך באופן אישי 
      `,
    ],
    cardAboutA: {
      title: `
    
רשת ביטחון לאוכלוסיות פגיעות

      `,
      desc: [
        `קשישים, אנשים עם מוגבלויות ומטופלים - כל מי שנמצא בסיכון גבוה לנפילות יכול להרוויח משכבת ההגנה החכמה שהמערכת מספקת. `,
      ],
    },

    cardAboutC: {
      title: 'זיהוי ואבחנה באתרי בניה',
      desc: [
        `התראות מיידיות לאירועי חירום ובעיות בטיחותיות שיכולות להציל חיים היכולות המתקדמות לניתוח נתונים, מערכת זיהוי שלנו יכולה לפתור בעיות בטיחותיות ולתת מענה לחברות בניה שרוצות לשפר ולדאוג לעובדים שלהם מבחינה בטיחותית.`,
      ],
    },
    DEFAULT_PRODUCT_LINK: [
      {
        title: 'מערכת ניהול רכב',
        label: 'for x industry',
        url: `/car-management-system`,
      },
      {
        title: 'ספירה',
        label: 'for Y industry',
        url: `/counting`,
      },
      {
        title: 'חָרוּץ',
        label: 'for f industry',
        url: `/industrion`,
      },
      {
        title: 'זיהוי אובייקטים',
        label: 'for a industry',
        url: `/object-detection`,
      },
    ],
  },
  objectDetection: {
    DEFAULT_CARD: {
      odd: false,
      img: product_img_1,
      isGeneralPage: false,
      date: '01/05',
      name: 'תוכנה חדשנית לזיהוי וסיווג אובייקטים',
      title: '',
      desc: `ברוכים הבאים לעידן חדש של זיהוי וסיווג אובייקטים בזמן אמת. הפתרון של WeSmart משלב אלגוריתמים חדישים וטכנולוגיית למידה עמוקה כדי לספק זיהוי מדויק ומהיר של אובייקטים מגוונים, מאנשים וכלי רכב ועד חפצים ואיומי אבטחה פוטנציאליים.`,
    },
    title: `הפתרונות שלנו`,
    desc: [
      `הפתרונות שלנו מספקים לך את השקט הנפשי והביטחון שאתה זקוק להם כדי להתמקד בדברים החשובים באמת
      .בוא לגלות מגוון רחב פתרונות מתואמות לך באופן אישי 
      `,
    ],
    cardAboutA: {
      title: `
      אין סוף אפשרויות 
      `,
      desc: [
        `המערכת שלנו מחזקת ומשפרת את יכולת האבטחה והשמירה על מתקנים חשובים שלכם, עם יכולת זיהוי ותגובה בזמן אמת, הפתרון שלנו מספק שכבת הגנה חיונית לארגונים בתעשיות השונות.`,
      ],
    },

    cardAboutC: {
      title: 'הפחתת סיכונים משמעותית',
      desc: [
        `המערכת מאפשרת זיהוי מהיר ומדויק של איומים בזמן אמת, מה שמוביל לתגובה מהירה יותר ומפחית סיכונים לארגון משפר את יכולת האבטחה ומחזק את ביטחון העובדים והתושבים. `,
      ],
    },
    DEFAULT_PRODUCT_LINK: [
      {
        title: 'מערכת ניהול רכב',
        label: 'for x industry',
        url: `/car-management-system`,
      },
      {
        title: 'ספירה',
        label: 'for Y industry',
        url: `/counting`,
      },
      {
        title: 'חָרוּץ',
        label: 'for f industry',
        url: `/industrion`,
      },
      {
        title: 'בטיחות',
        label: 'for a industry',
        url: `/safety`,
      },
    ],
  },
  security: {
    bg: sq_bg,
    card: {
      title: 'רוצה להתחבר איתנו?',
      desc: `יש לך שאלות, צורך בעזרה, או רוצה לחקור איך פתרונות ניתוח הווידאו של WeSmart יכולים להביא תועלת לארגונך? אנו כאן לעזור. צור קשר דרך הערוצים הבאים ונתחיל בשיחה.`,
    },
    appTitle: `אודות אפליקציה ניידת`,
    appDesc: [
      ` זהות, תצפה והגיבה לאירועים כפי שהם מתרחשים - הכל מתוך אפליקציה ניידת.`,
      `Lorem ipsum dolor sit amet consectetur. Purus tristique odio posuere et cursus. In scelerisque eu sit pulvinar et duis elit. Lorem ipsum dolor sit amet consectetur. Purus tristique odio posuere et cursus. In scelerisque eu sit pulvinar et duis elit. Lorem ipsum dolor sit amet consectetur. Purus tristique odio posuere et cursus. In scelerisque eu sit pulvinar et duis elit.`,
    ],
    title: `ביטחון ובטיחות`,
    desc: [
      `מילים על תחום זה.`,
      ` Lorem ipsum dolor sit amet consectetur. Purus tristique odio
    posuere et cursus. In scelerisque eu sit pulvinar et duis
    elit. Lorem ipsum dolor sit amet consectetur. Purus tristique
    odio posuere et cursus.`,
    ],
    CardAboutA: {
      title: `מגינים על מה שחשוב, מבטיחים שקט נפש`,
      desc: [
        `עבור אלה שאחראים על ביטחון, בטיחות וניהול סיכונים, פתרונות הסקרינג שלנו מספקים זיהוי ותגובה לאיומים בזמן אמת. אלגוריתמים שלמדים באופן עצמאי משפרים באופן תמידי את הדיוק, מתאימים לאתגרים ביטחוניים משתנים.
        בחר את Wesmart לפתרונות ביטחון ובטיחות מקיפים, ממצלמות מתקדמות לתמיכה מתמידה, להגנה על עסקך ועל רווחת העוסקים שלך.`,
      ],
    },
    CardAboutB: {
      title: `Lorem ipsum dolor`,
      desc: [
        `Lorem ipsum dolor sit amet consectetur. Purus
 tristique odio posuere et cursus. In scelerisque eu sit pulvinar et duis elit. `,
        `Lorem ipsum dolor
  sit amet consectetur. Purus tristique odio posuere et cursus. In scelerisque eu sit pulvinar et duis 
  elit. Lorem ipsum dolor sit amet consectetur. Purus tristique odio posuere et cursus. In scelerisque e
  u sit pulvinar et duis elit. Lorem ipsum dolor sit amet consectetur. Purus tristique odio posuere et cursus. 
  In scelerisque eu sit pulvinar et duis elit.`,
      ],
    },
    BENEFITS_CARDS_LOGISTICS: [
      {
        src: real,
        title: 'יעילות בזמן אמת  ',
        desc: `שדרג את פעולות השרשרת הספקים שלך עם פתרונותינו המופעלים על ידי AI. הרווח ראייה בזמן אמת לתוך תהליכי הלוגיסטיקה והתעשייה שלך, מקסימיזציה של היעילות בכל שלב.`,
      },
      {
        src: lock,
        title: 'תובנות מבוססות נתונים',
        desc: `הפלטפורמה שלנו מאפשרת לך לקבל החלטות מושכלות על ידי ניתוח נתוני וידאו. חלץ תובנות יקרות מתוך הפעולות שלך כדי לשפר את ההקצאה של המשאבים ולהגביר את היעילות.`,
      },
      {
        src: square,
        title: 'זיהוי סיכונים',
        desc: `הגבר על בטיחות ואבטחה בסביבות הלוגיסטיקה והתעשייה שלך. מערכת הסקרינג לווידאו החכמה שלנו זוהה סיכונים בזמן אמת, מפעילה התראות ומבטיחה סביבת עבודה בטוחה יותר.`,
      },
    ],
  },
  industrion: {
    DEFAULT_CARD: {
      odd: false,
      img: product_img_1,
      isGeneralPage: false,
      date: '01/05',
      name: 'Real-time fall detection system',
      title: '',
      desc: `The technology we have developed for the rapid and accurate detection of falls provides a safety net. WeSmart's system accurately identifies falls and knows how to distinguish between them and normal activities, thus avoiding unnecessary false alarms.`,
    },
    title: `הפתרונות שלנו`,
    desc: [
      `הפתרונות שלנו מספקים לך את השקט הנפשי והביטחון שאתה זקוק להם כדי להתמקד בדברים החשובים באמת
      .בוא לגלות מגוון רחב פתרונות מתואמות לך באופן אישי 
      `,
    ],
    cardAboutA: {
      title: `
      Safety net for vulnerable populations
      `,
      desc: [
        `The system includes a user-friendly and intuitive interface, where users can set alerts, track reports, and analyze data quickly and easily. `,
        `No technical training or in-depth understanding of the technology is required.`,
      ],
    },
    cardAboutC: {
      title: 'Detection and diagnosis at construction sites',
      desc: [
        `Immediate alerts for emergencies and safety issues that can save lives`,
        `With its advanced data analysis capabilities, our detection system can solve safety issues and provide a solution for construction companies that want to improve and care for their employees in terms of safety.`,
      ],
    },
    DEFAULT_PRODUCT_LINK: [
      {
        title: 'מערכת ניהול רכב',
        label: 'for x industry',
        url: `/car-management-system`,
      },
      {
        title: 'ספירה',
        label: 'for Y industry',
        url: `/counting`,
      },
      {
        title: 'זיהוי אובייקטים',
        label: 'for f industry',
        url: `/object-detection`,
      },
      {
        title: 'בטיחות',
        label: 'for a industry',
        url: `/safety`,
      },
    ],
  },
  counting: {
    DEFAULT_CARD: {
      odd: false,
      img: product_img_1,
      isGeneralPage: false,
      date: '01/05',
      name: 'מערכת ספירה מדויקת ',
      title: '',
      desc: `מערכת הספירה שלנו מיועדת לניטור וספירה של אנשים ורכבים על ידי יכולות  מתקדמות המאפשרים זיהוי מדויק גם בתנאים מאתגרים של צפיפות ועומס. תוצאות ספירה בזמן אמת מסייעות בקבלת החלטות מהירה וייעול תהליכים`,
    },
    title: `קלות שימוש ואינטגרציה`,
    desc: [
      `ממשק המשתמש הידידותי הופך את הטכנולוגיה המתקדמת לנגישה וקלה לתפעול עבור כל משתמש ומאפשר גישה קלה לנתונים ותובנות. המערכת משתלבת בקלות עם פתרונות מעקב קיימים, תוך הקפדה על הגנת הפרטיות. פשטות פוגשת יעילות לחוויית משתמש מיטבית.`,
    ],
    cardAboutA: {
      title: `
      הפתרון האולטימטיבי לניהול קהלים גדולים
      `,
      desc: [
        `
      אצטדיונים, תחנות רכבת, שדות תעופה אלו רק מספר מקומות שאנחנו יכולים להפיק דוחות ומידע ולנתח באופן אוטומטי את תנועת האנשים בזמן אמת. עם המערכת של WeSmart ניתן סוף סוף לנטר ולספור את הקהל במדויק, ולהשתמש בנתונים המתקבלים על מנת לייעל את אופן ההתנהלות.
      `,
      ],
    },
    // cardAboutB: {
    //   title: 'Benefits and positive impact',
    //   desc: [
    //     `The system allows for the receipt of immediate alerts for anomalous events, historical data analysis, and a user interface that is easy to understand - all of which contribute to informed decision-making and process optimization. `,
    //     `In addition, the system includes a wide range of customization options so that each user can tailor it to their specific needs`,
    //   ],
    // },
    cardAboutC: {
      title: 'בוא תייעל את העסק שלך ',
      desc: [
        `
        ניתוח אוטומטי של נתוני תנועת אנשים ורכבים מאפשר לגלות תובנות שלא היו נגישות קודם לכן. אופטימיזציה של תהליכים, שיפור האבטחה ועוד - נמצאים כעת בהישג יד עם כלי הניתוח המתקדמים של WeSmart.
        `,
      ],
    },
    DEFAULT_PRODUCT_LINK: [
      {
        title: 'מערכת ניהול רכב',
        label: 'for x industry',
        url: `/car-management-system`,
      },
      {
        title: 'חרוץ',
        label: 'for Y industry',
        url: `/industrion`,
      },
      {
        title: 'זיהוי אובייקטים',
        label: 'for f industry',
        url: `/object-detection`,
      },
      {
        title: 'בטיחות',
        label: 'for a industry',
        url: `/safety`,
      },
    ],
  },
  carSystem: {
    DEFAULT_CARD: {
      odd: false,
      img: product_img_1,
      isGeneralPage: false,
      date: '01/05',
      name: 'זיהוי מדויק של לוחיות רישוי ופרטי רכב',
      title: '',
      desc: `המערכת המתקדמת לזיהוי אוטומטי של לוחיות רישוי מבית WeSmart משלבת אלגוריתמים חדשניים עם עיבוד תמונה ברמה הגבוהה ביותר כדי להבטיח זיהוי מדויק בכל תנאי - ביום ובלילה, בכל מזג אוויר ובכל זווית צילום. יתרה מכך, המערכת מזהה גם פרטי רכב נוספים כגון יצרן, דגם, צבע וסוג - הכל באמצעות רשתות עצביות מתקדמות. ניתן לגשת להקלטות שביצעה המערכת לפי תאריך ושעה ובכך לחפש אירועים באופן יעיל.`,
    },
    title: `הפתרונות שלנו`,
    desc: [
      `הפתרונות שלנו מספקים לך את השקט הנפשי והביטחון שאתה זקוק להם כדי להתמקד בדברים החשובים באמת
      .בוא לגלות מגוון רחב פתרונות מתואמות לך באופן אישי 
      `,
    ],
    cardAboutA: {
      title: 'שליטה מלאה בניהול חניונים ',
      desc: [
        `המערכת מציעה כלים מתקדמים לניהול חניונים ופארקים, לרבות הגבלת מספר מקומות, בקרת כניסה לאזורים מותרים ואסורים לחניה ויצירת רשימות של מספרי לוחיות. כלל היכולות הללו בשילוב עם יכולת זיהוי ועיבוד נתונים בזמן אמת, הופכות את הפתרון שלנו לאידיאלי עבור ארגונים המעוניינים לשפר את הבקרה והניטור שלהם.`,
      ],
    },
    cardAboutB: {
      title: 'יתרונות והשפעה חיובית',
      desc: [
        `המערכת מאפשרת קבלת התראות מיידיות על אירועים חריגים, ניתוח נתונים היסטוריים וממש משתמש קל ופשוט להבנה - כל אלו תורמים לקבלת החלטות מושכלת ואופטימיזציה של תהליכים. בנוסף, המערכת כוללת מגוון רחב של אפשרויות התאמה כדי שלכל משתמש תהיה אפשרות להתאים אותה לצרכים הספציפיים שלו. `,
      ],
    },
    cardAboutC: {
      title: 'גמישות וקלות שימוש',
      desc: [
        `
        המערכת שלנו אוניברסלית ויכולה להתחבר לכל מצלמת IP, עם אפשרות חיבור מצלמות בלתי מוגבלת. קיימת תמיכה במסדי נתונים שונים, וכן אפליקציות לשימוש במכשירים סלולריים לנוחות מקסימלית. המערכת פועלת מצויין גם בזוויות צילום קיצוניות של עד 30 מעלות.
        `,
      ],
    },
    DEFAULT_PRODUCT_LINK: [
      {
        title: 'ספירה',
        label: 'for x industry',
        url: `/counting`,
      },
      {
        title: 'חרוץ',
        label: 'for Y industry',
        url: `/industrion`,
      },
      {
        title: 'זיהוי אובייקטים',
        label: 'for f industry',
        url: `/object-detection`,
      },
      {
        title: 'בטיחות',
        label: 'for a industry',
        url: `/safety`,
      },
    ],
  },
  preview: {
    title: `ביטחון, יעילות ופתרונות חכמים `,
    desc: `אנו מספקים פתרונות מתקדמים המאפשרים ללקוחותינו להפיק תועלת רבה מנתוני הווידאו שלהם.  
    המערכת הייחודית שלנו מנתחת שעות רבות של חומרי וידאו ומספקת תובנות עסקיות ותפעוליות שימושיות ביותר. 
    המערכת מעניקה יכולות זיהוי, אבחנה ומיפוי לתחומים רגישים הטכנולוגיה שלנו מספקת ביטחון, יעילות ופתרונות חכמים לעסקים וערים חכמות. 
    `,
    button: `גלה את מוצרינו`,
  },
  aboutUs: {
    title: `חדשנות בלתי פוסקת `,
    desc: [
      `WeSmart מובילה מהפכה שקטה בתחום הבינה המלאכותית, המעניקים למצלמות וחיישנים רגילים יכולות ניתוח, הבנה ותובנות.`,
      `המערכת הייחודית שפיתחנו נותנת מענה בתחומים שונים כמו אבטחה, תחבורה, שיפור עסקי וערים חכמות, הניסיון שצברנו מאפשר לנו לספק פתרונות איכותיים ומותאמים אישית.`,
    ],
    button: 'גלה את מוצרינו',
    ABOUT_US_ITEMS: [
      {
        label: 'למידע נוסף',
        title:
          'רוצה להבין כיצד הפתרונות המתקדמים שלנו יכולים לעזור לך באופן ספציפי? ',
        titleOfLink: 'לחץ כאן ',
        url: '/products',
      },
      {
        label: '',
        title:
          'אנחנו מאמינים בתקשורת פתוחה ושקיפות מלאה אל מול הלקוחות שלנו. אנחנו מזמינים אותך ליצור איתנו קשר כדי להבין בצורה מדויקת מהם הצרכים שלך וכדי לספק לך הדגמה אישית. ',
        url: '/new-case',
        titleOfLink: 'לחץ כאן כדי למלא טופס יצירת קשר',
      },
    ],
  },
  contactPage: {
    title: `בואו נדבר`,
    desc: `We are looking forward to hearing from you.
    Please select how we can help you below.`,
    CONTACT_CARDS: [
      {
        src: phone,
        title: 'בטלפון',
        desc: 'בימי ראשון עד שישי, בין השעות 9:00 ל17:00.',
        url: '+488880836',
        labelUrl: 'צור קשר עכשיו',
        phone: true,
      },
      {
        src: box,
        title: 'פתח מקרה חדש',
        desc: 'אנחנו פה לכל בעיה ולשמוע אתכם שלחו לנו מייל עכשיו .',
        url: 'new-case',
        labelUrl: 'פתח מקרה חדש',
      },
      {
        src: chat,
        title: `צ'אט חי`,
        desc: `צ'אט עם צ'אטבוט של WeSmart לקבלת תשובות לכל שאלה שלך.`,
        url: 'chatbot',
        labelUrl: `התחל צ'אט`,
      },
    ],
  },
  suggestions: {
    title: `בואו להכיר את הפתרונות המקיפים שלנו`,
    desc: `ב-Wesmart אנו מספקים מגוון פתרונות טכנולוגיים מותאמים אישית לצרכים הייחודיים של כל לקוח. מוצרינו נותנים מענה בתחומים שונים כמו אבטחה, תחבורה, שיפור עסקי וערים חכמות. הניסיון שצברנו מאפשר לנו לספק פתרונות איכותיים ומותאמים אישית. אנו מזמינים אתכם לבדוק את האפשרויות ולמצוא פתרונות מתאימים לצרכים הספציפיים שלכם.`,
    PRODUCT_CARDS: [
      {
        link: '/logistics',
        src: img_1,
        label: `  חדשנות למען מעמד עירוני מצוין  `,
        title: 'לוגיסטיקה ותעשייה ',
        desc: `בעידן התחרותי של היום, ניהול יעיל של שרשרת האספקה הוא המפתח להצלחה עסקית. פתרונות הבינה המלאכותית החדשניים שלנו מספקים ללקוחותינו תובנות אסטרטגיות ומעקב בזמן אמת אחר נתונים לוגיסטיים קריטיים. נשמח להראות לך כיצד תוכל לשפר ביצועים תפעוליים ועסקיים לצד חיסכון משמעותי בעלויות.`,
        titleOfLink: 'כדי לצפות בדוגמאות לחץ כאן. ',
      },
      {
        link: '/cities',
        src: img_3,
        label: ` חדשנות למען מעמד עירוני מצוין  `,
        title: 'עיר חכמה - חיים טובים יותר',
        desc: `בעידן המודרני, הטכנולוגיה מאפשרת לנו לבנות ערים חכמות ומתקדמות יותר. אנו מספקים פתרונות חדשניים לאבטחה, בטיחות וניהול יעיל של משאבים, המבוססים על ניתוח נתונים ובינה מלאכותית.
        הפתרונות שלנו מתאימים הן לערים גדולות והן ליישובים קטנים יותר, כאשר היישומים כוללים מערכות אזעקה, מצלמות אבטחה, בקרת גישה ועוד. במיוחד בתקופה הנוכחית, עם המצב הביטחוני המתוח בישראל, מערכות האבטחה שלנו יכולות לספק הגנה ומוכנות גבוהה יותר ליישובים. 
        אם אתה רוצה להפוך את התנהלות היישוב שלך לחכמה, בטוחה ויעילה יותר לחץ כאן
        `,
        titleOfLink: 'כדי לצפות בדוגמאות לחץ כאן. ',
      },
      {
        link: '/security',
        src: img_2,
        label: ` לוגיסטיקה חכמה, פעולות מותגמות `,
        title: 'בטיחות ואבטחה    ',
        desc: `
        בעולם של היום, בטיחות ואבטחה הם צורך הכרחי. פלטפורמות המעקב והניטור שפיתחנו יכולות לזהות בזמן אמת מצבים חשודים, להפעיל התראות מיידיות ובכך לשפר את רמת הבטיחות באופן משמעותי. הפתרונות שלנו מספקים הגנה מיטבית מפני סיכונים ואיומים.
        `,
        titleOfLink: 'כדי לצפות בדוגמאות לחץ כאן. ',
      },
    ],
  },
  benefits: {
    subTitle: `לקוחותינו הקודמים`,
    subDesc: 'מעוזר בהצלחה דרך יצירת חידוש משותף.',
    title: 'למה לבחור דווקא בנו?',
    desc: `
    ב-WeSmart אנו גאים להוביל את עולם הטכנולוגיה קדימה בפיתוח פתרונות חדשניים לניתוח וידאו. המקצועיות, המסירות והמחויבות שלנו למצוינות הפכו אותנו לשם דבר בתחום.
    `,
    BENEFITS_CARDS: [
      {
        src: icon_lock,
        title: 'התראות חכמות בזמן אמת ',
        desc: `בעולם דינמי כמו שלנו, יכולת זיהוי מהירה של אירועים חריגים היא קריטית. פלטפורמת ההתראות שלנו מזהה סטיות ומפיקה התראות מיידיות תוך שימוש באלגוריתמי למידה עצמית מתקדמים המשתפרים באופן רציף.`,
      },
      {
        src: icon_interface,
        title: `מותאם אישית לצרכים שלך`,
        desc: `אנו יודעים כיצד להתאים במדויק את פתרונות הבינה המלאכותית שלנו לדרישות הייחודיות שלך. כמו כן אנו יודעים לעבוד בשילוב עם התשתיות הקיימות שלכם.`,
      },
      {
        src: icon_heart,
        title: `מעטפת מלאה`,
        desc: `צוות המומחים שלנו נמצא לצדך בכל שלב - הטמעה, הפעלה ותפעול שוטף. אנו מבטיחים ביצועים מיטביים וחוויית משתמש מושלמת.`,
      },
      {
        src: icon_light,
        title: 'שיפור ביצועים ',
        desc: `כלי הדיווח והניתוח שלנו מספקים תובנות אסטרטגיות ברורות וניתנות ליישום כדי שאתם תוכלו לשפר ביצועים. `,
      },
      {
        src: icon_shuffle,
        title: 'פתרון מקצה לקצה',
        desc: `אנו מספקים פתרונות בינה מלאכותית מקיפים - חומרה, תוכנה, אמצעים רלוונטיים ותמיכה פתרון מקצה לקצה המיועד עבורך.`,
      },
    ],
    // clientCards: [
    //   {
    //     desc: `
    //     שיתוף הפעולה בין Colmobil ל WESTART הוא דוגמה מצוינת לאופן שבו טכנולוגיות אנליטקיה מתקדמות מבוססות בינה מלאכותית יכולות לתרום לשיפור רמת האבטחה והיעילות העסקית של חברות הרכב.
    //     `,
    //     name: `Colmobil`,
    //     position: ``,
    //     src: client1,
    //   },

    //   {
    //     desc: `חברת WESMART סיפקה פתרונות חדשניים ופיתוחים מהירים, נתנה ביצועים יוצאי דופן בתחום הספירה באזורים הומי אדם, זיהוי ואבחנה בפרויקטים מורכבים.`,
    //     name: `YSB`,
    //     position: ``,
    //     src: client2,
    //   },
    //   {
    //     desc: `העבודה עם צוות WeSmart  בראשות אור היא תהליך חלק, מהנה ופרודוקטיבי אור הוא איש מקצוע ובעל ניסיון רב בתחום הביטחון, אשר שיפור את הביטחון בישוב, הזמינות הייתה בכל שעה והמערכת שלהם פתרה לנו בעיות רבות ללא התרעות שווא.`,
    //     name: `Sdot Negev`,
    //     position: ``,
    //     src: client3,
    //   },
    // ],
  },
  contacts: {
    title: `אנחנו מזמינים אתכם לדבר איתנו!`,
    desc: `   ב-WeSmart אנו תמיד זמינים לענות לשאלותיכם ולסייע במימוש החזון הטכנולוגי שלכם. צוות המומחים שלנו נמצא כאן בשבילכם - להקשיב, להבין את האתגרים הייחודיים שלכם ולספק פתרונות טכנולוגיים מותאמים אישית לכל עסק, בין אם קטן, בינוני או גדול. יחד נוביל את המהפכה הדיגיטלית! 
    אל תחכו, צרו עמנו קשר עוד היום כדי שנוכל להתאים עבורכם פתרון טכנולוגי שייקח את העסק שלכם לרמה הבאה.
    `,
    button: `יצירת קשר כעת`,
  },
  footer: {
    copy: '© 2023 WESMART Communications AB. כל הזכויות שמורות.',
    cookie: 'הגדרות קובצי Cookie',
    legal: 'משפטי',
    policy: 'מדיניות פרטיות',
    address: 'רחוב גזית 10, כרמיאל',
    phone: 'מספר טלפון:',
    titleA: 'ניווט',
    titleB: 'מוצרים',
    titleC: 'יצירת קשר',
    titleD: 'כתובת  ',
    FOOTER_PRODUCTS: [
      {
        label: 'מערכת ניהול רכב',
        link: `/car-management-system`,
      },
      {
        label: 'ספירה',
        link: `/counting`,
      },
      {
        label: 'חרוץ',
        link: `/industrion`,
      },
      {
        label: 'זיהוי אובייקטים',
        link: `/object-detection`,
      },
      {
        label: 'בטיחות',
        link: `/safety`,
      },
    ],
  },
  aboutPage: {
    title: 'קצת עלינו',
    desc: `ב-WeSmart אנו מאמינים שהטכנולוגיה נועדה לשפר את איכות החיים וייעול תהליכים. 
    חברתנו, שנוסדה על ידי מומחים בתחום האנליטיקה והבינה המלאכותית, מתמחה בפיתוח פתרונות חכמים וחדשניים המשלבים מערכות ויזואליות שונות לצורך התמודדות עם אתגרים במגוון תחומים.
    אנו גאים לשתף פעולה עם לקוחות מובילים במשק ולספק להם כלים מתקדמים שמובילים לחיסכון בזמן ומשאבים יקרים. 
    `,
    button: 'גלה את מוצרינו',
    text: `החזון שלנו`,
    textBox: [
      `להפוך את העולם למקום בטוח יותר, אמין יותר ויעיל יותר באמצעות טכנולוגיה חדשנית המבוססת על נתונים.`,
    ],
    bannerTitle: 'טכנולוגיה שמשנה את חוקי המשחק',
    bannerDesc: `
    חברתנו גאה להוביל מהפכה טכנולוגית שזוכה להכרה בינלאומית רחבה, הפתרונות החדשניים שלנו כבר מיושמים בהצלחה על ידי אלפי לקוחות ברחבי העולם, וחוסכים עבורם מיליוני דולרים בשנה תוך שיפור האפקטיביות העסקית.
    השכר האמיתי עבורנו הוא לראות כיצד החדשנות שלנו מאפשרת לארגונים ולקהילות לפרוח ולממש את הפוטנציאל שלהם בצורה המיטבית.
    `,
    boxTitle: ` מאות לקוחות מרוצים`,
    boxDesc: `אנו גאים לשתף פעולה לאורך השנים עם מגוון רחב של חברות וארגונים מובילים במשק הישראלי ובעולם. בין לקוחותינו ניתן למנות את סלקום, אלביט מערכות, Nvidia, רשת מתחמי הדלק והקמעונאות TEN,  קבוצת שלטל ועוד רבים אחרים.
    חברות הענק והארגונים הקטנים כאחד מביעים שביעות רצון מהפתרונות הטכנולוגיים שפיתחנו עבורם ומהליווי המקצועי שהם מקבלים מאיתנו. 
    `,
    cardTitleD: 'למדו יותר על המוצרים והשירותים שלנו  ',
    cardDescD: [
      `הטכנולוגיות המתקדמות של WeSmart כבר מיושמות בהצלחה אצל מאות לקוחות ברחבי העולם ותורמות ערך אמיתי לעסקים במגוון תחומים. אם אתם מעוניינים ללמוד יותר על המוצרים והפתרונות הייחודיים שאנו מציעים וכיצד הם יכולים לסייע לכם, אנא לחצו כאן. `,
    ],
    cardTitleB: 'פתרונות מותאמים אישית',
    cardTitleC: 'הצוות שלנו   ',
    cardDescC: [`הצוות שלנו `],
    cardTitle: 'הערכים שמובילים אותנו ',
    cardDescB: [
      `
      אנחנו מאמינים שלכל ארגון יש צרכים ואתגרים טכנולוגיים ייחודיים. לכן, הגישה שלנו היא גמישה ומותאמת לכל לקוח באופן אישי. אנו בונים את הפתרונות תוך התחשבות בתשתיות ובמערכות הקיימות.
      צוות המומחים שלנו לומד ומבין לעומק את האתגרים הספציפיים, ולאחר מכן מתאים ללקוח פתרונות ייעודיים. התוצאה היא טכנולוגיה חדשנית ומתקדמת שנותנת מענה מדויק לצרכי הלקוח.
      `,
    ],
    cardDesc: [
      `חדשנות - החברה שואפת להיות מובילה בתחום הטכנולוגיה, ולהציע פתרונות חדשניים ללקוחותיה.`,
      `איכות - החברה מחויבת להציע מוצרים ושירותים באיכות גבוהה, שיתאימו לציפיות הלקוחות ולהתאים אליו `,
      ` שירות - החברה שואפת לספק שירות לקוחות מצוין, שיהיה זמין ומועיל.`,
      `אמינות - החברה מחויבת להיות חברה אמינה, שאפשר לסמוך עליה. `,
      `אחריות חברתית - החברה שואפת להחזיר לקהילה, ולתרום לחברה טובה יותר עם חיזוק הביטחון. `,
    ],
  },
  product: {
    title: ` מילים קצרות על המוצרים`,
    desc: [
      'הפתרונות שלנו',
      `  הפתרונות שלנו מספקים לך את השקט הנפשי והביטחון שאתה זקוק להם כדי להתמקד בדברים החשובים באמת
      .בוא לגלות מגוון רחב פתרונות מתואמות לך באופן אישי`,
    ],
    text: `גלה את מה שאנו מציעים`,
    PRODUCT_CARD: [
      {
        odd: true,
        url: '/car-management-system',
        img: product_img_1,
        isGeneralPage: true,
        date: '01/05',
        name: 'מערכת ניהול רכב',
        title: 'לוח רישוי מדויק',
        desc: `המערכת המתקדמת של WeSmart לזיהוי אוטומטי של לוחות רישוי משלבת אלגוריתמים חדשניים עם עיבוד תמונה מתקדם, מבטיחה זיהוי מדויק בכל תנאי - גם יום וגם לילה, בכל מזג אוויר ובכל זוויות המצלמה. `,
      },
      {
        odd: false,
        img: product_img_2,
        isGeneralPage: true,
        date: '02/05',
        url: '/counting',
        name: 'ספירה',
        title: '',
        desc: `המערכת כוללת ממשק פשוט ואינטואיטיבי למשתמש, בו המשתמשים יכולים להגדיר התראות, לעקוב אחר דוחות ולנתח נתונים בצורה מהירה וקלה`,
      },
      {
        odd: true,
        img: product_img_3,
        url: `/industrion`,
        isGeneralPage: true,
        date: '03/05',
        name: 'חרוץ',
        title: 'מערכת חכמה',
        desc: `אחת מהטכנולוגיות המתקדמות ביותר שפיתחנו היא זיהוי ומניעה של בעיות ייצור בתעשייה. `,
      },
      {
        odd: false,
        img: product_img_4,
        url: `/object-detection`,

        isGeneralPage: true,
        date: '04/05',
        name: 'זיהוי אובייקטים',
        title: 'זיהוי אובייקטים חדשני',
        desc: `ברוך הבא לעידן חדש של זיהוי וסיווג מערכותי של אובייקטים בזמן אמת. הפתרון של WeSmart משלב אלגוריתמים חדשניים וטכנולוגיית למידה עמוקה כדי לספק זיהוי מהיר ומדויק של מגוון רחב של אובייקטים - מאנשים ורכבים עד תוצרים ואיומים אפשריים על ביטחון`,
      },
      {
        odd: true,
        img: product_img_5,
        isGeneralPage: true,
        date: '05/05',
        url: `/safety`,
        name: 'בטיחות',
        title: 'בזמן אמת',
        desc: `הטכנולוגיה שפיתחנו לזיהוי מהיר ומדויק של נפילות מספקת רשת בטיחות. מערכת של WeSmart זוהה בדיוק נפילות ויודעת להבחין ביניהן לבין פעולות רגילות, מה שמונע את התרעות המיותרות`,
      },
    ],
  },
  description: {
    DEFAULT_CARD: {
      odd: false,
      img: product_img_1,
      isGeneralPage: false,
      date: '01/05',
      name: 'שם המוצר 1',
      title: 'כותרת',
      desc: `Description - Lorem ipsum dolor sit amet consectetur. Vitae donec et tincidunt lobortis risus sit.
       Vulputate eu faucibus felis lacinia tortor eu ultrices ac et. Lorem ipsum dolor sit amet consectetur. 
       Vitae donec et tincidunt lobortis risus sit. Vulputate eu faucibus felis lacinia tortor eu ultrices ac et.`,
    },
    title: `מסקנות`,
    desc: [
      'מילים כמסקנה.',
      'Lorem ipsum dolor sit amet consectetur. Purus tristique odio posuere et cursus. In scelerisque eu sit pulvinar et duis elit. Lorem ipsum dolor sit amet consectetur. Purus tristique odio posuere et cursus. In scelerisque eu sit pulvinar et duis elit. Lorem ipsum dolor sit amet consectetur. Purus tristique odio posuere et cursus. In scelerisque eu sit pulvinar et duis elit.',
      ` צור קשר כעת לדון במוצר או לחקור את ההצעות האחרות שאנו יכולים לספק.`,
    ],
    cardAboutA: {
      title: 'עבור תעשיית ABC',
      desc: [
        'מילה אחת על השימוש בתעשייה זו..',
        'Lorem ipsum dolor sit amet consectetur. Purus tristique odio posuere et cursus. In scelerisque eu sit pulvinar et duis elit. Lorem ipsum dolor sit amet consectetur. Purus tristique odio posuere et cursus. In scelerisque eu sit pulvinar et duis elit. Lorem ipsum dolor sit amet consectetur. Purus tristique odio posuere et cursus. In scelerisque eu sit pulvinar et duis elit.',
      ],
    },
    cardAboutB: {
      title: 'השפעה',
      desc: [
        'מילה אחת על השימוש בתעשייה זו.',
        'Lorem ipsum dolor sit amet consectetur. Purus tristique odio posuere et cursus. In scelerisque eu sit pulvinar et duis elit. Lorem ipsum dolor sit amet consectetur. Purus tristique odio posuere et cursus. In scelerisque eu sit pulvinar et duis elit. Lorem ipsum dolor sit amet consectetur. Purus tristique odio posuere et cursus. In scelerisque eu sit pulvinar et duis elit.',
      ],
    },
    cardAboutC: {
      title: 'מידע נוסף',
      desc: [
        'מילה אחת על השימוש בתעשייה זו.',
        'Lorem ipsum dolor sit amet consectetur. Purus tristique odio posuere et cursus. In scelerisque eu sit pulvinar et duis elit. Lorem ipsum dolor sit amet consectetur. Purus tristique odio posuere et cursus. In scelerisque eu sit pulvinar et duis elit. Lorem ipsum dolor sit amet consectetur. Purus tristique odio posuere et cursus. In scelerisque eu sit pulvinar et duis elit.',
      ],
    },
    DEFAULT_PRODUCT_LINK: [
      {
        title: 'מוצר 2',
        label: 'עבור תעשיית X',
      },
      {
        title: 'מוצר 3  ',
        label: 'עבור תעשיית Y',
      },
      {
        title: 'מוצר 4',
        label: 'עבור תעשיית F',
      },
      {
        title: 'מוצר 5',
        label: 'עבור תעשיית B',
      },
    ],
  },
  citiesPage: {
    bg: city_bg,
    card: {
      title: 'רוצה להתחבר איתנו?',
      desc: `יש לך שאלות, צורך בעזרה, או רוצה לחקור איך פתרונות ניתוח הווידאו של WeSmart יכולים להביא תועלת לארגונך? אנו כאן לעזור. צור קשר דרך הערוצים הבאים ונתחיל בשיחה.`,
    },
    appTitle: `About mobile app`,
    appDesc: [
      ` Detect, view, and respond to events as they unfold - all
    from a mobile app.`,
      ` Lorem ipsum dolor sit amet consectetur. Purus tristique odio
  posuere et cursus. In scelerisque eu sit pulvinar et duis
  elit. Lorem ipsum dolor sit amet consectetur. Purus
  tristique odio posuere et cursus. In scelerisque eu sit
  pulvinar et duis elit. Lorem ipsum dolor sit amet
  consectetur. Purus tristique odio posuere et cursus. In
  scelerisque eu sit pulvinar et duis elit.`,
    ],
    title: `ערים חכמות`,
    desc: [
      `מילים על תחום זה.`,
      ` Lorem ipsum dolor sit amet consectetur. Purus tristique odio
    posuere et cursus. In scelerisque eu sit pulvinar et duis
    elit. Lorem ipsum dolor sit amet consectetur. Purus tristique
    odio posuere et cursus.`,
    ],
    CardAboutA: {
      title: `הקרא את החיים העירוניים, שפר קיומיות`,
      desc: [
        `אם אתה מנהל ניהול עירוני, שירותים ציבוריים או תכנון עירוני, טכנולוגית המבוססת על נתונים שלנו ממירה את עירך לסביבה חכמה וקיומית יותר. אופטימיזציה של הקצאת משאבים, הפחתת השפעה סביבתית ושיפור כללי של איכות החיים לתושבים.`,
        `עם פתרונותינו של Wesmart, קדם יעילות ושפר בטיחות ואבטחה, והפוך את עירך למקום טוב יותר לחיות.`,
      ],
    },
    CardAboutB: {
      title: `Lorem ipsum dolor`,
      desc: [
        `Lorem ipsum dolor sit amet consectetur. Purus
 tristique odio posuere et cursus. In scelerisque eu sit pulvinar et duis elit. `,
        `Lorem ipsum dolor
  sit amet consectetur. Purus tristique odio posuere et cursus. In scelerisque eu sit pulvinar et duis 
  elit. Lorem ipsum dolor sit amet consectetur. Purus tristique odio posuere et cursus. In scelerisque e
  u sit pulvinar et duis elit. Lorem ipsum dolor sit amet consectetur. Purus tristique odio posuere et cursus. 
  In scelerisque eu sit pulvinar et duis elit.`,
      ],
    },
    BENEFITS_CARDS_LOGISTICS: [
      {
        src: wq,
        title: 'ניהול עירוני יעיל',
        desc: `אנו בונים תשתיות עירוניות חכמה באמצעות מערכות המבוססות על נתונים שמנהלות משאבים, שירותים וחיי היומיום בצורה יעילה. שפר את איכות החיים של התושבים וקדם קיומיות.`,
      },
      {
        src: wq2,
        title: 'אופטימיזציה של משאבים',
        desc: `פתרונותינו החכמים מאפשרים לערים לאופטימז את ההקצאה של משאבים, להפחית צריכת אנרגיה ולשפר את איכות החיים בערים בכלל. אנו עוזרים ליצירת עיר ירוקה ויעילה יותר.`,
      },
      {
        src: wq3,
        title: 'מודעות למצב',
        desc: `הבטיחו את בטיחותם ואבטחתם של תושבי העיר שלכם. אנו מספקים זיהוי אירועים ודיווח בזמן אמת, מה שהופך את הערים ליכולות יותר להגיב למצבים חדשים.`,
      },
    ],
  },
  solutionPage: {
    card: {
      title: 'רוצה להתחבר איתנו?',
      desc: `יש לך שאלות, צורך בעזרה, או רוצה לחקור איך פתרונות ניתוח הווידאו של 
      WeSmart יכולים להביא תועלת לארגונך? אנו כאן לעזור. צור קשר דרך הערוצים הבאים ונתחיל בשיחה.`,
    },
    appTitle: `אודות אפליקציה ניידת`,
    appDesc: [
      `זהות, תצפה והגיבה לאירועים כפי שהם מתרחשים - הכל מתוך אפליקציה ניידת.`,
      ` Lorem ipsum dolor sit amet consectetur. Purus tristique odio
  posuere et cursus. In scelerisque eu sit pulvinar et duis
  elit. Lorem ipsum dolor sit amet consectetur. Purus
  tristique odio posuere et cursus. In scelerisque eu sit
  pulvinar et duis elit. Lorem ipsum dolor sit amet
  consectetur. Purus tristique odio posuere et cursus. In
  scelerisque eu sit pulvinar et duis elit.`,
    ],
    title: `לוגיסטיקה ותעשייה`,
    desc: [
      `הפתרונות שלנו מספקים לך את השקט הנפשי והביטחון שאתה זקוק להם כדי להתמקד בדברים החשובים באמת
      .בוא לגלות מגוון רחב פתרונות מתואמות לך באופן אישי 
      `,
    ],
    CardAboutA: {
      title: `שפר יעילות, שדרג תוצאות`,
      desc: [
        `בין שאתה מפעיל מחצבה, מתקנת ייצור או ניהול חברת שילוח, הכלים שלנו לניתוח וידאו מעצימים אותך להגביר יעילות, לייעל פעולות ולהשיג את מטרות התעשייה הספציפיות שלך.`,
        `עם פתרונות מותאמים אישית המופעלים על ידי ניתוח וידאו של WeSmart, פתח את הפוטנציאל המלא של ההשקעה הטכנולוגית שלך לפתרונות לאתגרי הלוגיסטיקה והתעשייה שלך.`,
      ],
    },
    CardAboutB: {
      title: `Lorem ipsum dolor`,
      desc: [
        `Lorem ipsum dolor sit amet consectetur. Purus
 tristique odio posuere et cursus. In scelerisque eu sit pulvinar et duis elit. `,
        `Lorem ipsum dolor
  sit amet consectetur. Purus tristique odio posuere et cursus. In scelerisque eu sit pulvinar et duis 
  elit. Lorem ipsum dolor sit amet consectetur. Purus tristique odio posuere et cursus. In scelerisque e
  u sit pulvinar et duis elit. Lorem ipsum dolor sit amet consectetur. Purus tristique odio posuere et cursus. 
  In scelerisque eu sit pulvinar et duis elit.`,
      ],
    },
    BENEFITS_CARDS_LOGISTICS: [
      {
        src: real,
        title: 'יעילות בזמן אמת  ',
        desc: `שדרג את פעולות השרשרת הספקים שלך עם פתרונותינו המופעלים על ידי AI. הרווח ראייה בזמן אמת לתוך תהליכי הלוגיסטיקה והתעשייה שלך, מקסימיזציה של היעילות בכל שלב.`,
      },
      {
        src: lock,
        title: 'תובנות מבוססות נתונים',
        desc: `הפלטפורמה שלנו מאפשרת לך לקבל החלטות מושכלות על ידי ניתוח נתוני וידאו. חלץ תובנות יקרות מתוך הפעולות שלך כדי לשפר את ההקצאה של המשאבים ולהגביר את היעילות.`,
      },
      {
        src: square,
        title: 'זיהוי סיכונים',
        desc: `הגבר על בטיחות ואבטחה בסביבות הלוגיסטיקה והתעשייה שלך. מערכת הסקרינג לווידאו החכמה שלנו זוהה סיכונים בזמן אמת, מפעילה התראות ומבטיחה סביבת עבודה בטוחה יותר.`,
      },
    ],
  },
  newCase: {
    title: ` מספר מילים על מקרה זה`,
    desc: `    Lorem ipsum dolor sit amet consectetur. Purus tristique odio
    posuere et cursus. In scelerisque eu sit pulvinar et duis
    elit. Lorem ipsum dolor sit amet consectetur.`,
    subTitle: `אמצעי תקשורת מועדף:`,
    phoneLabel: `     טלפון`,
    emailLabel: `דוא"ל`,
  },
  chatBot: {
    title: ` שלום! אני צ'אטבוט של WeSmart`,
    desc: [
      ` אני כאן לעזור לך עם כל שאלה שיש לך על מוצרינו. בין אם אתה זקוק למידע על שירותינו, רוצה לחקור את הטרנדים האחרונים בתחום או פשוט יש לך שאלה, אני כאן לספק לך תשובות מהירות ומועילות. `,
      `איך אני יכול לעזור לך היום?`,
    ],
    CHATBOT_CARDS_DF: [
      {
        title: 'שאלה שנשאלת בתדירות 1',
        desc: 'הצטיינות יותר בניסוח של השאלה',
      },
      {
        title: 'שאלה שנשאלת בתדירות 2',
        desc: 'הצטיינות יותר בניסוח של השאלה',
      },
      {
        title: 'שאלה שנשאלת בתדירות 3',
        desc: 'הצטיינות יותר בניסוח של השאלה',
      },
      {
        title: 'שאלה שנשאלת בתדירות 4',
        desc: 'הצטיינות יותר בניסוח של השאלה',
      },
      {
        title: 'שאלה שנשאלת בתדירות 5',
        desc: 'הצטיינות יותר בניסוח של השאלה',
      },
      {
        title: 'שאלה שנשאלת בתדירות 6',
        desc: 'הצטיינות יותר בניסוח של השאלה',
      },
      {
        title: 'שאלה שנשאלת בתדירות 6',
        desc: 'הצטיינות יותר בניסוח של השאלה',
      },
      {
        title: 'שאלה שנשאלת בתדירות 6',
        desc: 'הצטיינות יותר בניסוח של השאלה',
      },
    ],
    chatMessage: ` התשובה הזו מתוך צ'אט המבוסס על הבנה מלאכותית. הצ'אט אמור לספק שאלה מורחבת לתשובה של המשתמש.
 
    Vitae donec et tincidunt lobortis risus sit. Vulputate eu faucibus felis lacinia tortor eu ultrices ac et. Lorem ipsum dolor sit amet consectetur. Vitae donec et tincidunt lobortis risus sit. Lorem ipsum dolor sit amet consectetur. Vitae donec et tincidunt lobortis risus sit.   Lorem ipsum dolor sit amet consectetur. Vitae donec et tincidunt lobortis risus sit. Vitae donec et tincidunt lobortis risus sit. `,
    placeholder: `שלח הודעה`,
  },
  form: {
    name: `שמך `,
    labelA: `הזן את שמך`,
    email: `דוא"ל `,
    labelB: `wesmart@example.com`,
    phone: `מספר טלפון Е`,
    labelC: `יש`,

    phonePlacholder: `+972 (00) 000-00-00`,
    company: `שם החברה `,
    textArea: `כתוב את ההודעה שלך`,
    text_area: `הודעה`,
    button: ` שלח בקשה`,
  },
};
