import React, {FC, useEffect, useState} from 'react';
import './Form.scss';
import {useSelector} from 'react-redux';
import {AppDispatch, RootState} from '../../store/store';
import {useDispatch} from 'react-redux';
import {sendMessage} from '../../store/message-post/actions';

const Form: FC = () => {
  const [isFocus, setIsFocus] = useState(false);
  const method = useSelector((state: RootState) => state.message);

  const [formData, setFormData] = useState({
    contact_method: method?.payload?.toLowerCase(),
    name: '',
    email: '',
    phone: '',
    company_name: '',
    message: '',
  });
  useEffect(() => {
    setFormData((prevState) => ({
      ...prevState,
      contact_method: method?.payload?.toLowerCase(),
    }));
  }, [method]);
  const dispatch: AppDispatch = useDispatch();

  const state = useSelector(
    (state: RootState) => state.language.currentLanguage.form,
  );

  const handelFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const {target} = e;
    let line = target.closest('.form__box')?.querySelector('.form__line ');
    line?.classList.add('active');
  };
  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const {target} = e;
    let line = target.closest('.form__box')?.querySelector('.form__line ');
    line?.classList.remove('active');
  };

  const handelChange = (e: any) => {
    const {name, value} = e.currentTarget;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    const {currentTarget} = e;
    if (currentTarget?.value.length >= 1) {
      currentTarget.nextElementSibling?.classList.add('active');
      return;
    } else {
      currentTarget.nextElementSibling?.classList.remove('active');
    }
  };
  const buttonCloseClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const currentButton = e.currentTarget; // получаем текущую кнопку
    const previousElement =
      currentButton.previousElementSibling as HTMLInputElement;
    previousElement.value = '';
    e.currentTarget.classList.remove('active');
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.preventDefault();

    // Проверяем, заполнены ли все поля
    if (
      formData.name.trim() === '' ||
      formData.email.trim() === '' ||
      formData.phone.trim() === '' ||
      formData.company_name.trim() === '' ||
      formData.message.trim() === ''
    ) {
      alert('Please fill in all fields');
      return;
    }
    dispatch(sendMessage(formData));
    setFormData({
      contact_method: method.payload.toLowerCase(),
      name: '',
      email: '',
      phone: '',
      company_name: '',
      message: '',
    });
    alert('Message has been sent');
  };

  return (
    <form action='' onSubmit={handleSubmit} name='form-submit' className='form'>
      <div className='form__box'>
        <label htmlFor='name'>{state.name} *</label>
        <div className='form__inner'>
          <input
            value={formData.name}
            onFocus={handelFocus}
            onBlur={handleBlur}
            onChange={handelChange}
            type='text'
            id='name'
            name='name'
            className='form__input'
            placeholder={state.labelA}
            required
          />
          <button
            className='form__button_close'
            onClick={buttonCloseClick}
          ></button>
        </div>
        <span className={`form__line `}></span>
      </div>
      <div className='form__box'>
        <label htmlFor='email'>{state.email} *</label>
        <div className='form__inner'>
          <input
            onFocus={handelFocus}
            onChange={handelChange}
            onBlur={handleBlur}
            id='email'
            value={formData.email}
            type='email'
            name='email'
            className='form__input'
            placeholder={state.labelB}
            required
          />
          <button
            className='form__button_close'
            onClick={buttonCloseClick}
          ></button>
        </div>
        <span className='form__line'></span>
      </div>
      <div className='form__box _phone'>
        <label htmlFor='phone'>{state.phone}</label>
        <div className='form__inner'>
          <ul className='form__list'>
            <li className='form__item'>{state.labelC}</li>
          </ul>
          <div className='form__flex'>
            <input
              id='phone'
              type='text'
              value={formData.phone}
              onChange={handelChange}
              onFocus={handelFocus}
              onBlur={handleBlur}
              name='phone'
              className='form__input'
              placeholder={state.phonePlacholder}
              required
            />
            <span className='form__line'></span>
          </div>
        </div>
      </div>
      <div className='form__box'>
        <label htmlFor='company'>{state.company} *</label>
        <div className='form__inner'>
          <input
            id='company'
            type='text'
            value={formData.company_name}
            name='company_name'
            onChange={handelChange}
            onFocus={handelFocus}
            onBlur={handleBlur}
            className='form__input'
            placeholder={state.labelB}
            required
          />
          <button
            className='form__button_close'
            onClick={buttonCloseClick}
          ></button>
        </div>
        <span className='form__line'></span>
      </div>
      <div className='form__box'>
        <label htmlFor='company'>{state.text_area} * </label>
        <textarea
          name='message'
          onChange={handelChange}
          value={formData.message}
          placeholder={state.textArea}
          className='form__textarea'
          required
        ></textarea>
      </div>
      <button type='submit' className='button-orange'>
        {state.button}
      </button>
    </form>
  );
};
export default Form;
