import React, {FC} from 'react';
import './ChatBotCard.scss';
import { ChatBotCardT } from '../../types/types';


const ChatBotCard: FC<ChatBotCardT> = ({info}) => {
  return (
    <button className='chat-bot-card'>
      <p className='chat-bot-card__title'>{info.title}</p>
      <p className='chat-bot-card__desc'>{info.desc}</p>
    </button>
  );
};

export default ChatBotCard;
