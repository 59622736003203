import CardAbout from '../../components/CardAbout/CardAbout';
import Header from '../../components/Header/Header';
import ProductCard from '../../components/ProductCard/ProductCard';
import './ProductDescriptionPage.scss';
import ProductCategory from '../../components/ProductCategory/ProductCategory';
import MobileHeader from '../../components/MobileHeader/MobileHeader';
import useResponsiveHeader from '../../components/useResponsiveHeader/useResponsiveHeader';
import {Link, useLocation, useParams} from 'react-router-dom';
import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {AppDispatch, RootState} from '../../store/store';
import {useDispatch} from 'react-redux';
import {getProductInfo} from '../../store/page-product-desc/actions';
import {getProductsInfo} from '../../store/page-products/actions';
import ParamParser from '../../helper/ParamParser';
import SEO from '../../SEO/SEO';

const ProductDescriptionPage = () => {
  const {isMobileHeader, isSmallScreen, showHeader, handleClick} =
    useResponsiveHeader();
  const {pathname} = useLocation();
  const {slug} = useParams();
  const dispatch: AppDispatch = useDispatch();
  const parserParam = ParamParser();
  const params = useSelector((state: RootState) => state?.language.params);
  const info = useSelector((state: RootState) => state?.product_page);
  const {product_pages_card} = info;
  const product_cards = useSelector(
    (state: RootState) => state?.all_products_page?.product_cards,
  );
  const info_footer = useSelector(
    (state: RootState) => state?.all_products_page,
  );
  const {footer} = info_footer;
  const state = useSelector((state: RootState) => state.language);

  useEffect(() => {
    document?.querySelector('body')?.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    dispatch(getProductsInfo(params));
  }, [params]);
  // useEffect(() => {
  //   dispatch(getProductInfo(params, slug as string));
  //   dispatch(getProductsInfo(params));
  // }, []);
  useEffect(() => {
    dispatch(getProductInfo(params, slug as string));
  }, [params, slug]);
  return (
    <div className={'product_desc_page'}>
      <SEO title={info?.title} description={info?.description} />
      <div className={'product_desc_page__wrap'}>
        {isSmallScreen && (
          <MobileHeader isActive={isMobileHeader} handelClick={handleClick} />
        )}
        <div className='bg-light-blue '>
          {showHeader && <Header />}
          <div className={'product_desc_page__preview'}>
            <ProductCard
              odd={true}
              card={{
                title: info.title,
                image: info.image,
                created_at: info.created_at,
                link_text: info.link_text,
                description: info.description,
              }}
            />
          </div>
        </div>
        <div>
          {product_pages_card?.map((item: any, index: any) => (
            <div key={index} className={`  product_desc_page__card `}>
              <CardAbout
                odd={index % 2 !== 0 ? true : false}
                src={item?.image}
                title={item?.title}
                desc={item?.description}
              />
            </div>
          ))}

          {/* <div className={` ${'product_desc_page__card'}  padding-space-top `}>
            <CardAbout
              odd={true}
              src={demo}
              title={product.cardAboutB.title}
              desc={product.cardAboutA.desc}
            />
          </div>
          <div className={`${'product_desc_page__card'} padding-space `}>
            <CardAbout
              src={demo}
              title={product.cardAboutC.title}
              desc={product.cardAboutA.desc}
            />
          </div> */}
        </div>
        <div className='bg-light-blue padding-space'>
          <div className='wrap'>
            <div className={'product_desc_page__row'}>
              <div className={'product_desc_page__info'}>
                <h2 className={'product_desc_page__title'}>{footer?.title}</h2>
                <p className={'product_desc_page__desc'}>
                  <span>{footer?.description}</span>
                </p>
                <Link
                  to={`/${state.selectedLanguage}/contacts`}
                  className={`${'product_desc_page__link'} button-orange`}
                >
                  {footer?.button_text}
                </Link>
              </div>
              <div className={'product_desc_page__categories'}>
                {product_cards?.map((category: any, i: any) => {
                  return <ProductCategory category={category} key={i} />;
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDescriptionPage;
