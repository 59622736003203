import React, {FC, useEffect} from 'react';
import './ProductCategory.scss';
import {replaceText} from '../../helper/funcs';
import {ProductCategoryT} from '../../types/types';
import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {AppDispatch, RootState} from '../../store/store';
import {useDispatch} from 'react-redux';
import {getProductsInfo} from '../../store/page-products/actions';

const ProductCategory: FC<ProductCategoryT> = ({category}) => {
  const state = useSelector(
    (state: RootState) => state.language.currentLanguage,
  );
  const lang = useSelector(
    (state: RootState) => state.language.selectedLanguage,
  );

  return (
    <div className='product-category'>
      <div className='product-category__wrap'>
        <span className='product-category__label'>{category.label}</span>
        <p className='product-category__title large-font '>{category.title}</p>
        <Link
          to={`/${lang}/products/${category?.slug}`}
          className='product-category__link link-more-light'
        >
          {state.button_more}
        </Link>
      </div>
    </div>
  );
};

export default ProductCategory;
