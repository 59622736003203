import {combineReducers} from 'redux';
import {languageReducer} from './languages/reducers';
import {getHeader} from './getHeader/actions';
import {HeaderReducer} from './getHeader/reducer';
import {FooterReducer} from './getFooter/reducer';
import {MainInfoReducer} from './page-main/reducer';
import {ContactReducer} from './contact/reducer';
import {MetaDataReducer} from './metadata/reducer';
import {AboutInfoReducer} from './page-about/reducer';
import {ContactInfoReducer} from './page-contact/reducer';
import {ProductsInfoReducer} from './page-products/reducer';
import {ProductDescInfoReducer} from './page-product-desc/reducer';
import {SolutionsInfoReducer} from './page-solutions/reducer';
import {MessageReducer} from './message-post/reducer';

export default combineReducers({
  language: languageReducer,
  header: HeaderReducer,
  footer: FooterReducer,
  main_page: MainInfoReducer,
  about_page: AboutInfoReducer,
  contact_page: ContactInfoReducer,
  all_products_page: ProductsInfoReducer,
  solutions_page: SolutionsInfoReducer,
  product_page: ProductDescInfoReducer,
  contact: ContactReducer,
  metadata: MetaDataReducer,
  message: MessageReducer,
});
