import {ActionsType, GET_CONTACT_INFO} from './actions';

const initialState = {};

export const ContactInfoReducer = (
  state = initialState,
  action: ActionsType,
) => {
  switch (action.type) {
    case GET_CONTACT_INFO: {
      return action.data;
    }
    default:
      return state;
  }
};
