import React, {FC} from 'react';
import './Card.scss';

interface CardI {
  item: {
    item_icon: string;
    item_title: string;
    item_description: string;
    icon?: string;
    description?: string;
    title?: string;
  };
}
const Card: FC<CardI> = ({item}) => {
  return (
    <div className='card-benefits'>
      <div className='card-benefits__box'>
        <div
          className='card-benefits__icon'
          style={{
            background: `center/cover no-repeat url(${
              item?.item_icon ? item?.item_icon : item.icon
            })`,
          }}
        ></div>
        <h4 className='h4 card-benefits__title'>
          {item?.item_title ? item?.item_title : item?.title}
        </h4>
      </div>
      <p className='card-benefits__desc small-font '>
        {item?.item_description ? item?.item_description : item.description}
      </p>
    </div>
  );
};

export default Card;
