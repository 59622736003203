export interface ParamsProps {
  method: string;
  headers: {
    'Content-Type': string;
    'Accept-Language': string;
    'Content-Security-Policy'?: string;
  };
}
export interface FormDataPops {
  contact_method: string;
  name: string;
  email: string;
  phone: string;
  company_name: string;
  message: string;
}
export const params_he: ParamsProps = {
  method: 'GET',
  headers: {
    'Content-Type': 'application/json',
    'Accept-Language': 'he',
    // 'Content-Security-Policy': ' upgrade-insecure-requests',
  },
};
export const params_en: ParamsProps = {
  method: 'GET',
  headers: {
    'Content-Type': 'application/json',
    'Accept-Language': 'en',
    // 'Content-Security-Policy': ' upgrade-insecure-requests',
  },
};
