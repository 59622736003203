import {GET_PRODUCT_INFO} from './actions';

export type MainProps = {
  type: typeof GET_PRODUCT_INFO;
  data: any;
};
export type ActionsType = MainProps;

const initialState = {};

export const ProductsInfoReducer = (
  state = initialState,
  action: ActionsType,
) => {
  switch (action.type) {
    case GET_PRODUCT_INFO: {
      return action.data;
    }
    default:
      return state;
  }
};
