import {ActionsType, GET_ABOUT_INFO} from './actions';

const initialState = {};

export const AboutInfoReducer = (state = initialState, action: ActionsType) => {
  switch (action.type) {
    case GET_ABOUT_INFO: {
      return action.data;
    }
    default:
      return state;
  }
};
