import React, {FC, useEffect, useState} from 'react';
import Navigation from './Navigation';
import {Link} from 'react-router-dom';
import SelectLanguage from '../SelectLanguage/SelectLanguage';
import {RootState} from '../../store/store';
import {useSelector} from 'react-redux';
import Logo from '../Logo/Logo';
import './Header.scss';
// import {setMainInfo} from '../../API/API';
// import {params_en} from '../../API/get-params';

const Header: FC = () => {
  const header = useSelector(
    (state: RootState) => state.language.currentLanguage,
  );
  const state = useSelector(
    (state: RootState) => state.language.selectedLanguage,
  );
  const info = useSelector((state: RootState) => state.header);
  return (
    <header className='header'>
      <div className='header__wrap wrap'>
        <Logo className='header__logo' />
        <nav className='header__nav'>
          <ul className='header__list'>
            <Navigation navLink={header.MENU_ITEMS} />
          </ul>
        </nav>
        <SelectLanguage />
        <Link
          to={`/${state}/${info?.button_url?.toLocaleLowerCase()}`}
          className='header__button button-orange'
        >
          {info?.button_text}
        </Link>
      </div>
    </header>
  );
};

export default Header;
