import React, {FC} from 'react';

import AboutUsLinks from './AboutUsLinks';
import './AboutUs.scss';
import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {RootState} from '../../store/store';
import {useInView} from 'react-intersection-observer';

const AboutUs: FC = () => {
  const about = useSelector(
    (state: RootState) => state.language.currentLanguage.aboutUs,
  );
  const lang = useSelector(
    (state: RootState) => state.language.selectedLanguage,
  );
  const info = useSelector(
    (state: RootState) => state.main_page.continuous_innovation,
  );
  const {ref, inView} = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });
  return (
    <div className='about-us' ref={ref}>
      <div className='about-us__wrap wrap'>
        {inView && (
          <div className='about-us__row'>
            <div className='about-us__info'>
              <h2 className=' h2 about-us__title '>{info?.title}</h2>
              <p className='about-us__desc medium-font  '>
                <span>{info?.description}</span>
              </p>
              <Link
                to={`/${lang}/products`}
                className='about-us__button button-orange '
              >
                {info?.button_title}
              </Link>
            </div>
            <div className='about-us__additional'>
              <span className='about-us__line'></span>
              <AboutUsLinks items={about.ABOUT_US_ITEMS} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AboutUs;
