import {CardT, CategoryT, ChatBotCardT} from '../types/types';
import img_1 from '../asserts/Logistic.png';
import img_2 from '../asserts/Security.png';
import img_3 from '../asserts/Smart-Cities.png';
import phone from '../asserts/phone-icon.svg';
import box from '../asserts/box-icon.svg';
import chat from '../asserts/chat-icon.svg';
import city_bg from '../asserts/Background-city.png';
import sq_bg from '../asserts/Background-seq.png';

import wq from '../asserts/interface-pad-lock-shield--combination-combo-lock-locked-padlock.svg';
import wq2 from '../asserts/interface-pad-lock-shield--combination-combo-lock-locked-padlock-secure-security-shield-square.svg';
import wq3 from '../asserts/interface-pad-lock-shield--combinatiod.svg';

import sq from '../asserts/interface-pad-lock-shield--combinationld-square.svg';
import lock from '../asserts/interface-pad-lock-shield.svg';
import pad from '../asserts/interface-pad.svg';

import product_img_1 from '../asserts/content-product_1.svg';
import product_img_2 from '../asserts/content-product_2.svg';
import product_img_3 from '../asserts/content-product_3.svg';
import product_img_4 from '../asserts/content-product_4.svg';
import product_img_5 from '../asserts/content-product_5.svg';

import client1 from '../asserts/logo2-cfsf-removebg-preview.png';
import client2 from '../asserts/ysb.png';
import client3 from '../asserts/logo2-cfs.png';

import icon_heart from '../asserts/icon-heart.svg';
import icon_interface from '../asserts/icon-interface.svg';

import icon_light from '../asserts/icon-scroll.svg';

import icon_shuffle from '../asserts/icon-shuffle.svg';
import icon_lock from '../asserts/icon-lock.svg';
import {InfoT} from './type';

type MenuItemsTypes = {
  label: string;
  link: string;
};

export const df_info_en: InfoT = {
  button_more: '  Learn more',
  button_title: 'Request a demo',
  safety: {
    DEFAULT_CARD: {
      odd: false,
      img: product_img_1,
      isGeneralPage: false,
      date: '01/05',
      name: 'Real-time fall detection system',
      title: '',
      desc: `The technology we have developed for the rapid and accurate detection of falls provides a safety net. WeSmart's system accurately identifies falls and knows how to distinguish between them and normal activities, thus avoiding unnecessary false alarms.`,
    },
    title: `Our solutions `,
    desc: [
      `Our solutions give you the confidence to let go of your worries and focus on what's important. Discover a wide range of personalized solutions that are tailored to your specific needs`,
    ],
    cardAboutA: {
      title: `
      Safety net for vulnerable populations
      `,
      desc: [
        `Seniors, people with disabilities, and patients - anyone who is at high risk of falling can benefit from the smart layer of protection that the system provides.`,
      ],
    },

    cardAboutC: {
      title: 'Detection and diagnosis at construction sites',
      desc: [
        `Immediate alerts for emergencies and safety issues that can save lives.`,
        `With its advanced data analysis capabilities, our detection system can solve safety issues and provide a solution for construction companies that want to improve and care for their employees in terms of safety.`,
      ],
    },
    DEFAULT_PRODUCT_LINK: [
      {
        title: 'Сar management system',
        label: 'Our solutions',
        url: `/car-management-system`,
      },
      {
        title: `Сounting 02\x05`,
        label: `Our solutions
      `,
        url: `/counting`,
      },
      {
        title: 'Industrious',
        label: 'Our solutions',
        url: `/industrion`,
      },
      {
        title: 'Object detection',
        label: 'Our solutions',
        url: `/object-detection`,
      },
    ],
  },
  objectDetection: {
    DEFAULT_CARD: {
      odd: false,
      img: product_img_1,
      isGeneralPage: false,
      date: '01/05',
      name: 'Innovative object detection and classification software',
      title: '',
      desc: `Welcome to a new era of real-time object detection and classification. WeSmart's solution combines innovative algorithms and deep learning technology to provide accurate and fast detection of a variety of objects, from people and vehicles to objects and potential security threats.`,
    },
    title: `Our solutions `,
    desc: [
      `Our solutions give you the confidence to let go of your worries and focus on what's important. Discover a wide range of personalized solutions that are tailored to your specific needs.`,
    ],
    cardAboutA: {
      title: `
      Endless possibilities
      `,
      desc: [
        `Our system strengthens and improves your ability to secure and protect your important facilities. `,
        `With real-time detection and response capabilities, our solution provides a critical layer of protection for organizations in a variety of industries.`,
      ],
    },

    cardAboutC: {
      title: 'Significant risk reduction',
      desc: [
        `The system enables rapid and accurate detection of threats in real time, leading to faster response and reduced risk to the organization. `,
        `It improves security capabilities and strengthens the security of employees and residents.`,
      ],
    },
    DEFAULT_PRODUCT_LINK: [
      {
        title: 'Сar management system',
        label: 'Our solutions',
        url: `/car-management-system`,
      },
      {
        title: 'Сounting',
        label: 'Our solutions',
        url: `/counting`,
      },
      {
        title: 'Industrious',
        label: 'Our solutions',
        url: `/industrion`,
      },
      {
        title: 'Safety',
        label: 'Our solutions',
        url: `/safety`,
      },
    ],
  },
  industrion: {
    DEFAULT_CARD: {
      odd: false,
      img: product_img_1,
      isGeneralPage: false,
      date: '01/05',
      name: 'Smart system for risk detection and prevention',
      title: '',
      desc: `One of the most advanced technologies we have developed is the detection and prevention of manufacturing problems in the industry. The system combines innovative image analysis algorithms with computer vision to automatically identify manufacturing defects and provide immediate alerts to prevent the waste of valuable resources.`,
    },
    title: `Our solutions `,
    desc: [
      `Our solutions give you the confidence to let go of your worries and focus on what's important. Discover a wide range of personalized solutions that are tailored to your specific needs.`,
    ],
    cardAboutA: {
      title: `
      A solution for every industry
      `,
      desc: [
        `The technology we have developed can be adapted to a wide range of work environments in different industries such as factories, construction sites, ports, storage facilities, and more. `,
        `We flexibly adapt to the unique needs and challenges of each industry and provide a ready-to-implement solution that is quick and easy to implement.`,
      ],
    },

    cardAboutC: {
      title: 'Contributes to employee safety',
      desc: [
        `Our technology helps to significantly reduce safety risks and prevent accidents. It ensures that employees comply with safety procedures, follow personal protective equipment guidelines, and assists in responding to emergencies.`,
        `The result is a significantly safer and healthier work environment.`,
      ],
    },
    DEFAULT_PRODUCT_LINK: [
      {
        title: 'Сar management system',
        label: 'Our solutions',
        url: `/car-management-system`,
      },
      {
        title: 'Сounting',
        label: 'Our solutions',
        url: `/counting`,
      },
      {
        title: 'Object detection',
        label: 'Our solutions',
        url: `/object-detection`,
      },
      {
        title: 'Safety',
        label: 'Our solutions',
        url: `/safety`,
      },
    ],
  },
  counting: {
    DEFAULT_CARD: {
      odd: false,
      img: product_img_1,
      isGeneralPage: false,
      date: '01/05',
      name: 'Real-time fall detection system',
      title: '',
      desc: `The technology we have developed for the rapid and accurate detection of falls provides a safety net. WeSmart's system accurately identifies falls and knows how to distinguish between them and normal activities, thus avoiding unnecessary false alarms.`,
    },
    title: `Our solutions `,
    desc: [
      `Our solutions give you the confidence to let go of your worries and focus on what's important. Discover a wide range of personalized solutions that are tailored to your specific needs.`,
    ],
    cardAboutA: {
      title: `
      Safety net for vulnerable populations
      `,
      desc: [
        `The system includes a user-friendly and intuitive interface, where users can set alerts, track reports, and analyze data quickly and easily. `,
        `No technical training or in-depth understanding of the technology is required.`,
      ],
    },
    cardAboutC: {
      title: 'Detection and diagnosis at construction sites',
      desc: [
        `Immediate alerts for emergencies and safety issues that can save lives`,
        `With its advanced data analysis capabilities, our detection system can solve safety issues and provide a solution for construction companies that want to improve and care for their employees in terms of safety.`,
      ],
    },
    DEFAULT_PRODUCT_LINK: [
      {
        title: 'Сar management system',
        label: 'Our solutions',
        url: `/car-management-system`,
      },
      {
        title: 'Industrious',
        label: 'Our solutions',
        url: `/industrion`,
      },
      {
        title: 'Object detection',
        label: 'Our solutions',
        url: `/object-detection`,
      },
      {
        title: 'Safety',
        label: 'Our solutions',
        url: `/safety`,
      },
    ],
  },
  carSystem: {
    DEFAULT_CARD: {
      odd: false,
      img: product_img_1,
      isGeneralPage: false,
      date: '01/05',
      name: 'Accurate license plate and vehicle identification',
      title: '',
      desc: `The WeSmart advanced automatic license plate recognition system combines innovative algorithms with state-of-the-art image processing to ensure accurate identification in any condition - day or night, in any weather, and at any camera angle. In addition, the system also identifies additional vehicle details such as manufacturer, model, color, and type - all using advanced neural networks. Recordings made by the system can be accessed by date and time, allowing efficient event search.`,
    },
    title: `Our solutions `,
    desc: [
      `Our solutions give you the confidence to let go of your worries and focus on what's important. Discover a wide range of personalized solutions that are tailored to your specific needs.`,
    ],
    cardAboutA: {
      title: `
      Full control over parking management
      `,
      desc: [
        `The system offers advanced tools for managing parking lots and parks, including limiting the number of spaces, controlling access to permitted and prohibited parking areas, and creating lists of license plate numbers. `,
        `All of these capabilities, combined with the ability to identify and process data in real time, make our solution ideal for organizations that want to improve their control and monitoring.`,
      ],
    },
    cardAboutB: {
      title: 'Benefits and positive impact',
      desc: [
        `The system allows for the receipt of immediate alerts for anomalous events, historical data analysis, and a user interface that is easy to understand - all of which contribute to informed decision-making and process optimization. `,
        `In addition, the system includes a wide range of customization options so that each user can tailor it to their specific needs`,
      ],
    },
    cardAboutC: {
      title: 'Flexibility and ease of use',
      desc: [
        `Our system is universal and can be connected to any IP camera, with the option of connecting an unlimited number of cameras. There is support for different databases, as well as applications for use on mobile devices for maximum convenience. `,
        `The system also works great at extreme camera angles of up to 30 degrees.`,
      ],
    },
    DEFAULT_PRODUCT_LINK: [
      {
        title: 'Counting',
        label: 'Our solutions',
        url: `/counting`,
      },
      {
        title: 'Industrious',
        label: 'Our solutions',
        url: `/industrion`,
      },
      {
        title: 'Object detection',
        label: 'Our solutions',
        url: `/object-detection`,
      },
      {
        title: 'Safety',
        label: 'Our solutions',
        url: `/safety`,
      },
    ],
  },
  MENU_ITEMS: [
    {
      label: 'Home',
      link: '/',
    },
    {
      label: 'About',
      link: '/about',
    },
    {
      label: 'Products',
      link: '/products',
    },
    // {
    //   label: 'Blog',
    //   link: '/blog',
    // },
    {
      label: 'Contact',
      link: '/contacts',
    },
  ],
  contactPage: {
    title: `Let’s talk`,
    desc: `We are looking forward to hearing from you.
      Please select how we can help you below.`,
    CONTACT_CARDS: [
      {
        src: phone,
        title: 'By phone',
        desc: 'Sunday – Friday  9:00 – 17:00',
        url: '+488880836',
        labelUrl: 'Call us now',
        phone: true,
      },
      {
        src: box,
        title: 'Open new case ',
        desc: 'Just send us  your questions by starting a new case and we will give you the help you need.',
        url: 'new-case',
        labelUrl: 'Start a new case',
      },
      {
        src: chat,
        title: 'Live chat',
        desc: 'Chat with a member of our in-house team.',
        url: 'chatbot',
        labelUrl: 'Start chat',
      },
    ],
  },
  preview: {
    title: 'Security, efficiency, and smart solutions',
    desc: `We provide advanced solutions that allow our customers to get the most out of their video data. Our unique system analyzes hours of video footage and provides highly useful business and operational insights. The system provides identification, diagnosis, and mapping capabilities for sensitive areas. Our technology provides security, efficiency, and smart solutions for businesses and smart cities.`,
    button: `Explore our product`,
  },
  aboutUs: {
    title: ` Continuous innovation`,
    desc: [
      ` WeSmart is leading a quiet revolution in the field of artificial intelligence, giving ordinary cameras and sensors the ability to analyze, understand, and gain insights. `,
      `  Our unique system developed meets the needs of various fields such as security, transportation, business improvement, and smart cities. Our accumulated experience allows us to provide high-quality and customized solutions.`,
    ],
    button: 'Explore our product',
    ABOUT_US_ITEMS: [
      {
        label: ' ',
        title:
          'Do you want to understand how our advanced solutions can help you specifically?',
        titleOfLink: 'Click here',
        url: '/products',
      },
      {
        label: '',
        title:
          'We believe in open communication and full transparency with our customers. We invite you to contact us to accurately understand your needs and provide you with a personal demonstration.',
        titleOfLink: 'Click here to fill out a contact form',
        url: '/new-case',
      },
    ],
  },
  suggestions: {
    title: `Come and get to know our comprehensive solutions`,
    desc: `At Wesmart, we provide a variety of customized technological solutions to meet the unique needs of each customer. Our products meet the needs of various fields such as security, transportation, business improvement, and smart cities. Our accumulated experience allows us to provide high-quality and customized solutions. We invite you to explore the possibilities and find solutions that meet your specific needs.`,
    PRODUCT_CARDS: [
      {
        link: '/logistics',

        src: img_1,
        label: `  Intelligent Logistics, Optimized Operations  `,
        title: 'Logistics and industry',
        desc: `In today's competitive era, efficient supply chain management is the key to business success. Our innovative artificial intelligence solutions provide our customers with strategic insights and real-time monitoring of critical logistics data. We would be happy to show you how you can improve operational and business performance alongside significant cost savings. `,
        titleOfLink: 'To see examples, click here',
      },
      {
        link: '/cities',
        src: img_3,
        label: ` Innovating for Urban Excellence  `,
        title: 'Smart city - better life ',
        desc: `WeSmart's smart city solutions help create safer, more efficient, and more connected communities. Our solutions can be used to improve traffic flow, prevent crime, and provide better public services. We are committed to helping cities around the world become smarter and more livable.`,
        titleOfLink: 'To see examples, click here',
      },
      {
        link: '/security',
        src: img_2,
        label: ` Innovating for Urban Excellence `,
        title: 'Safety and Security',
        desc: `In today's world, safety and security are essential needs. The tracking and monitoring platforms we've developed can identify suspicious situations in real-time, trigger immediate alerts, significantly improving safety levels. Our solutions offer excellent protection against risks and threats. `,
        titleOfLink: 'To see examples, click here',
      },
    ],
  },
  benefits: {
    subTitle: 'Our Previous Clients',
    subDesc: 'Empowering Success Through Collaborative Innovation.',
    title: 'Why Choose Us?',
    desc: ` At WeSmart, we take pride in leading the technological world by developing innovative video analysis solutions. Our professionalism, dedication, and commitment to excellence have made us a reputable name in the field.`,
    BENEFITS_CARDS: [
      {
        src: icon_lock,
        title: 'Real-time Smart Alerts  ',
        desc: `In our dynamic world, quickly identifying unusual events is critical. Our alert platform detects anomalies and issues immediate notifications using advanced machine learning algorithms that continuously improve.`,
      },
      {
        src: icon_interface,
        title: 'Customized for Your Needs',
        desc: `We precisely tailor our artificial intelligence solutions to meet your unique requirements and work in conjunction with your existing infrastructure.`,
      },
      {
        src: icon_heart,
        title: 'Full Envelope',
        desc: `Our expert team stands by your side at every stage - implementation, operation, and continuous support. We promise optimal performance and a perfect user experience.`,
      },
      {
        src: icon_light,
        title: 'Performance Improvement',
        desc: `Our reporting and analysis tools provide clear strategic insights that are implementable, allowing you to enhance performance.`,
      },
      {
        src: icon_shuffle,
        title: 'End-to-End Solution  ',
        desc: `We offer comprehensive artificial intelligence solutions - hardware, software, relevant means, and end-to-end solution support tailored for you.`,
      },
    ],
    // clientCards: [
    //   {
    //     desc: `
    //     The collaboration between Colmobil and WESTART is a
    //     great example of how advanced analytics technologies based
    //     on artificial intelligence can contribute to improving the
    //     security and business efficiency of car companies.`,
    //     name: `Colmobil`,
    //     position: ``,
    //     src: client1,
    //   },

    //   {
    //     desc: `WESMART has provided innovative solutions and rapid developments, delivering exceptional performance in the field of counting in crowded areas, identification, and diagnosis in complex projects.`,
    //     name: `YSB`,
    //     position: ``,
    //     src: client2,
    //   },
    //   {
    //     desc: `Working with the WeSmart team led by Or was a smooth, enjoyable, and productive process. Or is a professional with extensive experience in the field of security, who has improved the security of the settlement. The availability was around the clock, and their system solved many problems for us without false alarms.`,
    //     name: `Sdot Negev`,
    //     position: ``,
    //     src: client3,
    //   },
    // ],
  },
  contacts: {
    title: `Let's Talk!`,
    desc: `  At WeSmart, we're always available to answer your questions and assist in realizing your technological vision. Our expert team is here for you - to listen, understand your unique challenges, and provide tailor-made technological solutions for any business, whether small, medium, or large. Together, let's lead the digital revolution! Don't wait, contact us today, so we can tailor a technological solution that takes your business to the next level.`,
    button: `Contact us now`,
  },
  footer: {
    copy: ' © 2023 WESMART Communications AB. All rights reserved.',
    cookie: 'Cookie settings',
    legal: 'Legal',
    policy: 'Privacy policy',
    address: '     Gazit 10 St, Karmiel.',
    phone: 'Phone number:',
    titleA: 'Navigation',
    titleB: 'Products',
    titleC: 'Contacts',
    titleD: 'Address ',
    FOOTER_PRODUCTS: [
      {
        label: 'Сar management system',
        link: `/car-management-system`,
      },
      {
        label: 'Сounting',
        link: `/counting`,
      },
      {
        label: 'Industrious',
        link: `/industrion`,
      },
      {
        label: 'Object detection',
        link: `/object-detection`,
      },
      {
        label: 'Safety',
        link: `/safety`,
      },
    ],
  },
  aboutPage: {
    title: 'About Us',
    desc: 'SWeSmart believes that technology is meant to improve the quality of life and streamline processes. Our company, founded by experts in the field of analytics and artificial intelligence, specializes in the development of smart and innovative solutions that combine various visual systems to address challenges in a variety of fields. We are proud to collaborate with leading customers in the market and provide them with advanced tools that lead to time and cost savings.',
    button: 'Explore our product',
    text: `Our Vision`,
    textBox: [
      `To make the world a safer, more reliable, and more efficient place through innovative data-driven technology.`,
    ],
    boxTitle: ` Hundreds of satisfied customers`,
    boxDesc: `We are proud to have collaborated over the years with a wide range of leading companies and organizations in the Israeli and global economy. Among our customers are Cellcom, Elbit Systems, Nvidia, TEN fuel and retail chain, the Shtal group, and many others. Both large corporations and small organizations express satisfaction with the technological solutions we have developed for them and the professional support they receive from us.`,
    bannerTitle: 'Technology that changes the rules of the game',
    bannerDesc: ` Our company is proud to lead a technological revolution that is gaining wide international recognition. Our innovative solutions are already successfully implemented by thousands of customers around the world, saving them millions of dollars a year while improving business efficiency. The real reward for us is to see how our innovation enables organizations and communities to thrive and realize their full potential in the best possible way.`,
    cardTitleD: 'Learn more about our products and services',
    cardDescD: [
      `WeSmart's advanced technologies are already successfully implemented by hundreds of customers around the world and contribute real value to businesses in a variety of fields. If you are interested in learning more about the unique products and solutions we offer and how they can help you, please click here.`,
    ],
    cardTitleC: 'Our team  ',
    cardDescC: [
      `We believe that the most important asset is human capital, the professional team that leads technological innovation in the organization. The company is led by the CEO and owner of the company Shlomi Ohana, the owner of the Shtal group, alongside Lior Einav, CTO and data analysis expert. Marketing and business development are in the professional hands of Or Lavan, a young and accomplished CEO with exceptional achievements. In addition, we employ a team of top-notch software engineers, and we focus on excellence and professionalism.`,
    ],
    cardTitleB: 'Customized solutions',
    cardTitle: 'Our Values',
    cardDesc: [
      `•	Innovation - The company strives to be a leader in the field of technology and to offer innovative solutions to its customers. `,
      `•	Quality - The company is committed to offering high-quality products and services that meet customer expectations and personalize the product.`,
      `•	Service - The company strives to provide excellent customer service that is available and helpful.`,
      `•	Reliability - The company is committed to being a reliable company that can be trusted`,
      `•	Social Responsibility - The company strives to give back to the community and contribute to a better society by strengthening security.`,
    ],
    cardDescB: [
      `We believe that each organization has unique technological needs and challenges. Therefore, our approach is flexible and tailored to each customer individually. We build solutions taking into account existing infrastructure and systems. Our team of experts learns and understands the specific challenges, and then customizes dedicated solutions for the customer. The result is innovative and advanced technology that provides a precise response to customer needs.`,
    ],
  },
  product: {
    title: ` A few words about products`,
    desc: [
      'Our solutions ',
      ` Our solutions give you the confidence to let go of your worries and focus on what's important. Discover a wide range of personalized solutions that are tailored to your specific needs.`,
    ],
    text: `Explore what we offer`,
    PRODUCT_CARD: [
      {
        odd: true,
        url: '/car-management-system',

        img: product_img_1,
        isGeneralPage: true,
        date: '01/05',
        name: 'Car management system and LPR',
        title: 'Accurate license plate ',
        desc: `The WeSmart advanced automatic license plate recognition system combines innovative algorithms with state-of-the-art image processing to ensure accurate identification in any condition - day or night, in any weather, and at any camera angle. `,
      },
      {
        odd: false,
        img: product_img_2,
        isGeneralPage: true,
        date: '02/05',
        url: '/counting',
        name: 'Counting',
        title: '',
        desc: `Our counting system is designed to monitor and count people and vehicles using advanced capabilities that enable accurate identification even in challenging conditions of crowding and congestion. Real-time counting results help to make quick decisions and optimize processes.`,
      },
      {
        odd: true,
        img: product_img_3,
        url: `/industrion`,
        isGeneralPage: true,
        date: '03/05',
        name: 'Industrious',
        title: 'Smart system',
        desc: `One of the most advanced technologies we have developed is the detection and prevention of manufacturing problems in the industry. `,
      },
      {
        odd: false,
        img: product_img_4,
        url: `/object-detection`,

        isGeneralPage: true,
        date: '04/05',
        name: 'Object Detection',
        title: 'Innovative object detection',
        desc: `Welcome to a new era of real-time object detection and classification. WeSmart's solution combines innovative algorithms and deep learning technology to provide accurate and fast detection of a variety of objects, from people and vehicles to objects and potential security threats.`,
      },
      {
        odd: true,
        img: product_img_5,
        isGeneralPage: true,
        date: '05/05',
        url: `/safety`,
        name: 'Safety',
        title: 'Real-time',
        desc: `The technology we have developed for the rapid and accurate detection of falls provides a safety net. WeSmart's system accurately identifies falls and knows how to distinguish between them and normal activities, thus avoiding unnecessary false alarms.`,
      },
    ],
  },
  description: {
    DEFAULT_CARD: {
      odd: false,
      img: product_img_1,
      isGeneralPage: false,
      date: '01/05',
      name: 'Product name 1',
      title: 'Title',
      desc: `Description - Lorem ipsum dolor sit amet consectetur. Vitae donec et tincidunt lobortis risus sit.
       Vulputate eu faucibus felis lacinia tortor eu ultrices ac et. Lorem ipsum dolor sit amet consectetur. 
       Vitae donec et tincidunt lobortis risus sit. Vulputate eu faucibus felis lacinia tortor eu ultrices ac et.`,
    },
    title: `Conclusions`,
    desc: [
      'Some word about using in this industry.',
      'Lorem ipsum dolor sit amet consectetur. Purus tristique odio posuere et cursus. In scelerisque eu sit pulvinar et duis elit. Lorem ipsum dolor sit amet consectetur. Purus tristique odio posuere et cursus. In scelerisque eu sit pulvinar et duis elit. Lorem ipsum dolor sit amet consectetur. Purus tristique odio posuere et cursus. In scelerisque eu sit pulvinar et duis elit.',
      ` Contact us now to discuss the product, or explore other
      offers we can provide.`,
    ],
    cardAboutA: {
      title: 'For ABC industry',
      desc: [
        'Some word about using in this industry.',
        'Lorem ipsum dolor sit amet consectetur. Purus tristique odio posuere et cursus. In scelerisque eu sit pulvinar et duis elit. Lorem ipsum dolor sit amet consectetur. Purus tristique odio posuere et cursus. In scelerisque eu sit pulvinar et duis elit. Lorem ipsum dolor sit amet consectetur. Purus tristique odio posuere et cursus. In scelerisque eu sit pulvinar et duis elit.',
      ],
    },
    cardAboutB: {
      title: 'Impact',
      desc: [
        'Some word about using in this industry.',
        'Lorem ipsum dolor sit amet consectetur. Purus tristique odio posuere et cursus. In scelerisque eu sit pulvinar et duis elit. Lorem ipsum dolor sit amet consectetur. Purus tristique odio posuere et cursus. In scelerisque eu sit pulvinar et duis elit. Lorem ipsum dolor sit amet consectetur. Purus tristique odio posuere et cursus. In scelerisque eu sit pulvinar et duis elit.',
      ],
    },
    cardAboutC: {
      title: 'Additional info',
      desc: [
        'Some word about using in this industry.',
        'Lorem ipsum dolor sit amet consectetur. Purus tristique odio posuere et cursus. In scelerisque eu sit pulvinar et duis elit. Lorem ipsum dolor sit amet consectetur. Purus tristique odio posuere et cursus. In scelerisque eu sit pulvinar et duis elit. Lorem ipsum dolor sit amet consectetur. Purus tristique odio posuere et cursus. In scelerisque eu sit pulvinar et duis elit.',
      ],
    },
    DEFAULT_PRODUCT_LINK: [
      {
        title: 'Product 2',
        label: 'Our solutions',
      },
      {
        title: 'Product 3',
        label: 'Our solutions',
      },
      {
        title: 'Product 4',
        label: 'Our solutions',
      },
      {
        title: 'Product 5',
        label: 'Our solutions',
      },
    ],
  },
  security: {
    bg: sq_bg,
    card: {
      title: 'Want to connect with us?',
      desc: `Have questions, need assistance, or want to explore how Wesmart's 
      video analytics solutions can benefit your organization? We're here to help. 
      Contact us through the following channels and let's start a conversation. `,
    },
    appTitle: `About mobile app`,
    appDesc: [
      ` Detect, view, and respond to events as they unfold - all
    from a mobile app.`,
      ` Lorem ipsum dolor sit amet consectetur. Purus tristique odio
  posuere et cursus. In scelerisque eu sit pulvinar et duis
  elit. Lorem ipsum dolor sit amet consectetur. Purus
  tristique odio posuere et cursus. In scelerisque eu sit
  pulvinar et duis elit. Lorem ipsum dolor sit amet
  consectetur. Purus tristique odio posuere et cursus. In
  scelerisque eu sit pulvinar et duis elit.`,
    ],
    title: `Security & Safety`,
    desc: [
      `Some words about this field.`,
      ` Lorem ipsum dolor sit amet consectetur. Purus tristique odio
    posuere et cursus. In scelerisque eu sit pulvinar et duis
    elit. Lorem ipsum dolor sit amet consectetur. Purus tristique
    odio posuere et cursus.`,
    ],
    CardAboutA: {
      title: `Enhance efficiency, improve outcomes`,
      desc: [
        `Whether you operate a warehouse, a manufacturing facility, or manage a shipping company, our video
       analysis tools empower you to boost efficiency, streamline operations, and achieve your industry-specific goals.  `,
        `With tailored solutions driven by Wesmart's video analytics, unlock the full potential of your technology investment
       for solutions to your logistics and industry challenges.`,
      ],
    },
    CardAboutB: {
      title: `Lorem ipsum dolor`,
      desc: [
        `Lorem ipsum dolor sit amet consectetur. Purus
 tristique odio posuere et cursus. In scelerisque eu sit pulvinar et duis elit. `,
        `Lorem ipsum dolor
  sit amet consectetur. Purus tristique odio posuere et cursus. In scelerisque eu sit pulvinar et duis 
  elit. Lorem ipsum dolor sit amet consectetur. Purus tristique odio posuere et cursus. In scelerisque e
  u sit pulvinar et duis elit. Lorem ipsum dolor sit amet consectetur. Purus tristique odio posuere et cursus. 
  In scelerisque eu sit pulvinar et duis elit.`,
      ],
    },
    BENEFITS_CARDS_LOGISTICS: [
      {
        src: pad,
        title: 'Real-Time Alerts & Adaptive Learning    ',
        desc: `Enhance your supply chain operations with our AI-driven solutions. Gain real-time visibility into your
         logistics and industry processes, optimizing efficiency at every step.`,
      },
      {
        src: lock,
        title: 'Data-Driven Insights',
        desc: `Our platform empowers you to make informed decisions by analyzing video data. Extract valuable insights from your
         operations to improve resource allocation and boost productivity.`,
      },
      {
        src: sq,
        title: 'Risk Detection',
        desc: `Boost safety and security in your logistics and industrial settings. Our intelligent video surveillance system
         identifies risks in real-time, triggering alerts and ensuring a safer work environment. `,
      },
    ],
  },
  citiesPage: {
    bg: city_bg,
    card: {
      title: 'Want to connect with us?',
      desc: `Have questions, need assistance, or want to explore how Wesmart's 
      video analytics solutions can benefit your organization? We're here to help. 
      Contact us through the following channels and let's start a conversation. `,
    },
    appTitle: `About mobile app`,
    appDesc: [
      ` Detect, view, and respond to events as they unfold - all
    from a mobile app.`,
      ` Lorem ipsum dolor sit amet consectetur. Purus tristique odio
  posuere et cursus. In scelerisque eu sit pulvinar et duis
  elit. Lorem ipsum dolor sit amet consectetur. Purus
  tristique odio posuere et cursus. In scelerisque eu sit
  pulvinar et duis elit. Lorem ipsum dolor sit amet
  consectetur. Purus tristique odio posuere et cursus. In
  scelerisque eu sit pulvinar et duis elit.`,
    ],
    title: `Smart Cities`,
    desc: [
      `Some words about this field.`,
      ` Lorem ipsum dolor sit amet consectetur. Purus tristique odio
    posuere et cursus. In scelerisque eu sit pulvinar et duis
    elit. Lorem ipsum dolor sit amet consectetur. Purus tristique
    odio posuere et cursus.`,
    ],
    CardAboutA: {
      title: `Enhance efficiency, improve outcomes`,
      desc: [
        `Whether you operate a warehouse, a manufacturing facility, or manage a shipping company, our video
       analysis tools empower you to boost efficiency, streamline operations, and achieve your industry-specific goals.  `,
        `With tailored solutions driven by Wesmart's video analytics, unlock the full potential of your technology investment
       for solutions to your logistics and industry challenges.`,
      ],
    },
    CardAboutB: {
      title: `Lorem ipsum dolor`,
      desc: [
        `Lorem ipsum dolor sit amet consectetur. Purus
 tristique odio posuere et cursus. In scelerisque eu sit pulvinar et duis elit. `,
        `Lorem ipsum dolor
  sit amet consectetur. Purus tristique odio posuere et cursus. In scelerisque eu sit pulvinar et duis 
  elit. Lorem ipsum dolor sit amet consectetur. Purus tristique odio posuere et cursus. In scelerisque e
  u sit pulvinar et duis elit. Lorem ipsum dolor sit amet consectetur. Purus tristique odio posuere et cursus. 
  In scelerisque eu sit pulvinar et duis elit.`,
      ],
    },
    BENEFITS_CARDS_LOGISTICS: [
      {
        src: wq,
        title: 'Efficient City Management',
        desc: `We build smarter city infrastructure through data-driven systems that efficiently manage resources, services, and daily life. Enhance the quality of life for residents and promote sustainability.`,
      },
      {
        src: wq2,
        title: 'Resource Optimization',
        desc: `Our smart solutions enable cities to optimize resource allocation, reduce energy consumption, and enhance the overall quality of urban life. We help create a greener, more efficient city.`,
      },
      {
        src: wq3,
        title: 'Situational Awareness',
        desc: `Ensure the safety and security of your city's residents. We provide real-time incident detection and reporting, making cities more responsive to emerging situations. `,
      },
    ],
  },
  solutionPage: {
    card: {
      title: 'Want to connect with us?',
      desc: `Have questions, need assistance, or want to explore how Wesmart's 
      video analytics solutions can benefit your organization? We're here to help. 
      Contact us through the following channels and let's start a conversation. `,
    },
    appTitle: `About mobile app`,
    appDesc: [
      ` Detect, view, and respond to events as they unfold - all
    from a mobile app.`,
      ` Lorem ipsum dolor sit amet consectetur. Purus tristique odio
  posuere et cursus. In scelerisque eu sit pulvinar et duis
  elit. Lorem ipsum dolor sit amet consectetur. Purus
  tristique odio posuere et cursus. In scelerisque eu sit
  pulvinar et duis elit. Lorem ipsum dolor sit amet
  consectetur. Purus tristique odio posuere et cursus. In
  scelerisque eu sit pulvinar et duis elit.`,
    ],
    title: `Logistics & Industry`,
    desc: [
      ` Our technology is versatile and can be adapted to meet the needs of businesses in a variety of industries, including manufacturing, construction, transportation, and logistics. Our solutions are easy to implement and can help businesses improve efficiency, productivity, and safety.`,
    ],
    CardAboutA: {
      title: `Enhance efficiency, improve outcomes`,
      desc: [
        `Whether you operate a warehouse, a manufacturing facility, or manage a shipping company, our video
       analysis tools empower you to boost efficiency, streamline operations, and achieve your industry-specific goals.  `,
        `With tailored solutions driven by Wesmart's video analytics, unlock the full potential of your technology investment
       for solutions to your logistics and industry challenges.`,
      ],
    },
    CardAboutB: {
      title: `Lorem ipsum dolor`,
      desc: [
        `Lorem ipsum dolor sit amet consectetur. Purus
 tristique odio posuere et cursus. In scelerisque eu sit pulvinar et duis elit. `,
        `Lorem ipsum dolor
  sit amet consectetur. Purus tristique odio posuere et cursus. In scelerisque eu sit pulvinar et duis 
  elit. Lorem ipsum dolor sit amet consectetur. Purus tristique odio posuere et cursus. In scelerisque e
  u sit pulvinar et duis elit. Lorem ipsum dolor sit amet consectetur. Purus tristique odio posuere et cursus. 
  In scelerisque eu sit pulvinar et duis elit.`,
      ],
    },
    BENEFITS_CARDS_LOGISTICS: [
      {
        src: pad,
        title: 'Real-Time Alerts & Adaptive Learning    ',
        desc: `Enhance your supply chain operations with our AI-driven solutions. Gain real-time visibility into your
         logistics and industry processes, optimizing efficiency at every step.`,
      },
      {
        src: lock,
        title: 'Data-Driven Insights',
        desc: `Our platform empowers you to make informed decisions by analyzing video data. Extract valuable insights from your
         operations to improve resource allocation and boost productivity.`,
      },
      {
        src: sq,
        title: 'Risk Detection',
        desc: `Boost safety and security in your logistics and industrial settings. Our intelligent video surveillance system
         identifies risks in real-time, triggering alerts and ensuring a safer work environment. `,
      },
    ],
  },

  chatBot: {
    title: `   Hello! I'm the WeSmart Chatbot`,
    desc: [
      `  I’m here to assist you with any questions you have about
      our products. Whether you need information about our
      services, want to explore the latest trends in the field,
      or simply have a query, I'm here to provide you with quick
      and helpful answers. `,
      `How can I assist you today?`,
    ],
    CHATBOT_CARDS_DF: [
      {
        title: '1st Frequently asked question',
        desc: 'More detailed wording of the question',
      },
      {
        title: '2nd Frequently asked question',
        desc: 'More detailed wording of the question',
      },
      {
        title: '3rd Frequently asked question',
        desc: 'More detailed wording of the question',
      },
      {
        title: '4 Frequently asked question',
        desc: 'More detailed wording of the question',
      },
      {
        title: '5 Frequently asked question',
        desc: 'More detailed wording of the question',
      },
      {
        title: '6 Frequently asked question',
        desc: 'More detailed wording of the question',
      },
      {
        title: '6 Frequently asked question',
        desc: 'More detailed wording of the question',
      },
      {
        title: '6 Frequently asked question',
        desc: 'More detailed wording of the question',
      },
    ],
    chatMessage: `Lorem ipsum dolor sit amet consectetur. Vitae donec et tincidunt lobortis risus sit. Vulputate eu faucibus
    felis lacinia tortor eu ultrices ac et. Lorem ipsum dolor sit amet consectetur.

    Vitae donec et tincidunt lobortis risus sit. Vulputate eu faucibus felis lacinia tortor eu ultrices ac et. 
    Lorem ipsum dolor sit amet consectetur. Vitae donec et tincidunt lobortis
    risus sit. Lorem ipsum dolor sit amet consectetur. Vitae donec et tincidunt lobortis risus sit.

    Lorem ipsum dolor sit amet consectetur. Vitae donec et tincidunt lobortis risus sit. `,
    placeholder: `Sent a message`,
  },
  newCase: {
    title: `  A few words about this case`,
    desc: `    Lorem ipsum dolor sit amet consectetur. Purus tristique odio
    posuere et cursus. In scelerisque eu sit pulvinar et duis
    elit. Lorem ipsum dolor sit amet consectetur.`,
    subTitle: `Preferred method of communication:`,
    phoneLabel: `     Phone`,
    emailLabel: `Email`,
  },
  form: {
    name: `Your name`,
    labelA: `Enter your name`,
    email: `Email`,
    labelB: `wesmart@example.com`,
    phone: `Phone number`,
    labelC: `ISR`,
    phonePlacholder: `+972 (00) 000-00-00`,
    company: `Company name`,
    text_area: `Message`,
    textArea: `Write your message`,
    button: ` Send request`,
  },
};

export const MENU_ITEMS: Array<MenuItemsTypes> = [
  {
    label: 'Home',
    link: '/',
  },
  {
    label: 'About',
    link: '/about',
  },
  {
    label: 'Products',
    link: '/products',
  },
  // {
  //   label: 'Blog',
  //   link: '/blog',
  // },
  {
    label: 'Contact',
    link: '/contacts',
  },
];
// products for footer
// export const FOOTER_PRODUCTS: Array<MenuItemsTypes> = [
//   {
//     label: 'Product 1',
//     link: '/products/1',
//   },
//   {
//     label: 'Product 2',
//     link: '/products/2',
//   },
//   {
//     label: 'Product 3',
//     link: '/products/3',
//   },
//   {
//     label: 'Product 4',
//     link: '/products/4',
//   },
//   {
//     label: 'Product 5',
//     link: '/products/5',
//   },
// ];

// for About us
// export type AboutUsType = {
//   label: string;
//   title: string;
//   titleOfLink: string;
// };

// export const ABOUT_US_ITEMS: Array<AboutUsType> = [
//   {
//     label: 'Learn more',
//     title: 'How Wesmart can help you',
//     titleOfLink: 'Learn more about our product',
//   },
//   {
//     label: 'communication',
//     title:
//       'Contact us to discuss your specific needs or request a personalized demonstration',
//     titleOfLink: 'Fill out the feedback form',
//   },
// ];

// for card of products

// export const PRODUCT_CARDS: Array<ProductCardType> = [
//   {
//     link: '/logistics',
//     src: img_1,
//     label: `  Intelligent Logistics, Optimized Operations  `,
//     title: 'Logistics & Industry  ',
//     desc: `Our AI solutions provide real-time visibility and actionable insights across your
//     supply chain - driving efficiency at every step. `,
//     titleOfLink: 'Learn more',
//   },
//   {
//     link: '/cities',
//     src: img_3,
//     label: ` Innovating for Urban Excellence  `,
//     title: 'Smart Cities ',
//     desc: `We build smarter city infrastructure through data-driven systems that
//      manage resources, services, and daily life with efficiency and sustainability `,
//     titleOfLink: 'Learn more',
//   },
//   {
//     link: '/security',
//     src: img_2,
//     label: ` Innovating for Urban Excellence `,
//     title: 'Security & Safety    ',
//     desc: `Peace of Mind Through Advanced Protection. Our intelligent video surveillance platforms
//     detect risks in real-time and trigger alerts - combining computer vision and AI to maximize
//      safety and security.`,
//     titleOfLink: 'Learn more',
//   },
// ];

// for card of benefits
type BenefitsCardType = {
  src: string;
  title: string;
  desc: string;
};
// export const BENEFITS_CARDS: Array<BenefitsCardType> = [
//   {
//     src: icon_lock,
//     title: 'Real-Time Alerts & Adaptive Learning    ',
//     desc: `The system identifies anomalies and generates real-time alerts.
//     Our self-learning algorithms continuously improve detection accuracy.`,
//   },
//   {
//     src: icon_interface,
//     title: 'Optimized for Your Needs    ',
//     desc: `We tailor our solutions to your specific requirements through
//     collaborative partnerships - integrating smoothly with existing infrastructure.`,
//   },
//   {
//     src: icon_heart,
//     title: 'Ongoing Support and Maintenance    ',
//     desc: `Our team provides hands-on support during deployment and continued assistance
//      to ensure optimal performance long-term. `,
//   },
//   {
//     src: icon_light,
//     title: 'Actionable Insights    ',
//     desc: `Powerful reporting tools provide data-driven insights to inform your operational
//     strategies and plans. `,
//   },
//   {
//     src: icon_shuffle,
//     title: 'End-to-End Solution    ',
//     desc: `As an industry leader, we offer complete solutions - from cameras
//      to cutting-edge software and support.`,
//   },
// ];

// export const BENEFITS_CARDS_LOGISTICS: Array<BenefitsCardType> = [
//   {
//     src: `../../asserts/interface-pad.svg`,
//     title: 'Real-Time Alerts & Adaptive Learning    ',
//     desc: `Enhance your supply chain operations with our AI-driven solutions. Gain real-time visibility into your
//      logistics and industry processes, optimizing efficiency at every step.`,
//   },
//   {
//     src: `../../asserts/interface-pad-lock-shield.svg`,
//     title: 'Data-Driven Insights',
//     desc: `Our platform empowers you to make informed decisions by analyzing video data. Extract valuable insights from your
//      operations to improve resource allocation and boost productivity.`,
//   },
//   {
//     src: `../../asserts/interface-pad-lock-shield--combinationld-square.svg`,
//     title: 'Risk Detection',
//     desc: `Boost safety and security in your logistics and industrial settings. Our intelligent video surveillance system
//      identifies risks in real-time, triggering alerts and ensuring a safer work environment. `,
//   },
// ];
// about page
export const BASIC_TEXT: Array<string> = [
  `Lorem ipsum dolor sit amet consectetur. Purus tristique odio posuere et cursus. In 
scelerisque eu sit pulvinar et duis elit. `,
  `Lorem ipsum dolor sit amet consectetur. Purus tristique odio posuere et cursus. 
  In scelerisque eu sit pulvinar et duis elit. Lorem ipsum dolor sit amet consectetur.
   Purus tristique odio posuere et cursus. In scelerisque eu sit pulvinar et duis elit.
    Lorem ipsum dolor sit amet consectetur. Purus tristique odio posuere et cursus. 
    In scelerisque eu sit pulvinar et duis elit.`,
];

// ProductPage

// export const PRODUCT_CARD: Array<CardT> = [
//   {
//     odd: true,
//     img: product_img_1,
//     isGeneralPage: true,
//     date: '01/05',
//     name: 'Product name 1',
//     title: 'Title',
//     desc: `Description - Lorem ipsum dolor sit amet consectetur. Vitae donec et tincidunt lobortis risus sit.
//    Vulputate eu faucibus felis lacinia tortor eu ultrices ac et. Lorem ipsum dolor sit amet consectetur.
//    Vitae donec et tincidunt lobortis risus sit. Vulputate eu faucibus felis lacinia tortor eu ultrices ac et.`,
//   },
//   {
//     odd: false,
//     img: product_img_2,
//     isGeneralPage: true,
//     date: '02/05',
//     name: 'Product name 2',
//     title: 'Title',
//     desc: `Description - Lorem ipsum dolor sit amet consectetur. Vitae donec et tincidunt lobortis risus sit.
//    Vulputate eu faucibus felis lacinia tortor eu ultrices ac et. Lorem ipsum dolor sit amet consectetur.
//    Vitae donec et tincidunt lobortis risus sit. Vulputate eu faucibus felis lacinia tortor eu ultrices ac et.`,
//   },
//   {
//     odd: true,
//     img: product_img_3,

//     isGeneralPage: true,
//     date: '03/05',
//     name: 'Product name 3',
//     title: 'Title',
//     desc: `Description - Lorem ipsum dolor sit amet consectetur. Vitae donec et tincidunt lobortis risus sit.
//    Vulputate eu faucibus felis lacinia tortor eu ultrices ac et. Lorem ipsum dolor sit amet consectetur.
//    Vitae donec et tincidunt lobortis risus sit. Vulputate eu faucibus felis lacinia tortor eu ultrices ac et.`,
//   },
//   {
//     odd: false,
//     img: product_img_4,

//     isGeneralPage: true,
//     date: '04/05',
//     name: 'Product name 4',
//     title: 'Title',
//     desc: `Description - Lorem ipsum dolor sit amet consectetur. Vitae donec et tincidunt lobortis risus sit.
//    Vulputate eu faucibus felis lacinia tortor eu ultrices ac et. Lorem ipsum dolor sit amet consectetur.
//    Vitae donec et tincidunt lobortis risus sit. Vulputate eu faucibus felis lacinia tortor eu ultrices ac et.`,
//   },
//   {
//     odd: true,
//     img: product_img_5,
//     isGeneralPage: true,
//     date: '05/05',
//     name: 'Product name 5',
//     title: 'Title',
//     desc: `Description - Lorem ipsum dolor sit amet consectetur. Vitae donec et tincidunt lobortis risus sit.
//    Vulputate eu faucibus felis lacinia tortor eu ultrices ac et. Lorem ipsum dolor sit amet consectetur.
//    Vitae donec et tincidunt lobortis risus sit. Vulputate eu faucibus felis lacinia tortor eu ultrices ac et.`,
//   },
// ];
// export const DEFAULT_CARD: CardT = {
//   odd: false,
//   img: product_img_1,
//   isGeneralPage: false,
//   date: '01/05',
//   name: 'Product name 1',
//   title: 'Title',
//   desc: `Description - Lorem ipsum dolor sit amet consectetur. Vitae donec et tincidunt lobortis risus sit.
//    Vulputate eu faucibus felis lacinia tortor eu ultrices ac et. Lorem ipsum dolor sit amet consectetur.
//    Vitae donec et tincidunt lobortis risus sit. Vulputate eu faucibus felis lacinia tortor eu ultrices ac et.`,
// };

// ProductDescriptionPage
export const DEFAULT_PRODUCT_LINK: Array<CategoryT> = [
  {
    title: 'Product 2',
    label: 'for x industry',
  },
  {
    title: 'Product 3',
    label: 'for Y industry',
  },
  {
    title: 'Product 4',
    label: 'for f industry',
  },
  {
    title: 'Product 5',
    label: 'for a industry',
  },
];

// CHAT BOT CARDSA
export const CHATBOT_CARDS_DF = [
  {
    title: '1st Frequently asked question',
    desc: 'More detailed wording of the question',
  },
  {
    title: '2nd Frequently asked question',
    desc: 'More detailed wording of the question',
  },
  {
    title: '3rd Frequently asked question',
    desc: 'More detailed wording of the question',
  },
  {
    title: '4 Frequently asked question',
    desc: 'More detailed wording of the question',
  },
  {
    title: '5 Frequently asked question',
    desc: 'More detailed wording of the question',
  },
  {
    title: '6 Frequently asked question',
    desc: 'More detailed wording of the question',
  },
  {
    title: '6 Frequently asked question',
    desc: 'More detailed wording of the question',
  },
  {
    title: '6 Frequently asked question',
    desc: 'More detailed wording of the question',
  },
];
