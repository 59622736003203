import React, {FC} from 'react';
import './ProductCard.scss';
import {ProductCardT} from '../../types/types';
import {Link} from 'react-router-dom';
import {replaceText} from '../../helper/funcs';
import {useSelector} from 'react-redux';
import {Root} from 'react-dom/client';
import {RootState} from '../../store/store';

const ProductCard: FC<ProductCardT> = ({card, odd, isGeneralPage}) => {
  const state = useSelector((state: RootState) => state.language);
  const date = new Date(card.created_at);
  const day = date.getDate();
  const month = date.getMonth() + 1;

  const formattedDate = `${day}/${month < 10 ? '0' + month : month}`;

  return (
    <div className={`product-card ${odd ? 'active' : 'none'}`}>
      <div className='product-card__wrap'>
        <div className='product-card__row'>
          <div
            className='product-card__img'
            style={{background: `right/cover no-repeat url(${card.image})`}}
          ></div>
          <div className='product-card__info'>
            <div className='product-card__box'>
              <span className='product-card__date'>{formattedDate}</span>
              <h2 className='h2 product-card__name'>{card.title}</h2>
              <div
                className='product-card__img'
                style={{background: `right/cover no-repeat url(${card.image})`}}
              ></div>
            </div>
            <div className='product-card__container'>
              <h4 className='h4 product-card__title'>{card.label}</h4>
              <p className='product-card__desc'>{card.description}</p>
              {isGeneralPage ? (
                <Link
                  to={`/${
                    state.selectedLanguage
                  }/products/${card?.slug?.toLocaleLowerCase()}`}
                  className='product-card__link'
                >
                  {card.link_text}
                </Link>
              ) : (
                <button className='product-card__button button-orange'>
                  {card.link_text}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
