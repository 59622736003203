import {ParamsProps} from '../../API/get-params';
import {setProductInfo} from '../../API/API';
import {AppDispatch} from '../store';

export const GET_PRODUCT_INFO = 'page-products/GET_PRODUCT_INFO';

export type ProductsPageProp = {
  type: typeof GET_PRODUCT_INFO;
  data: any;
};
export type ActionsType = ProductsPageProp;

export const getProductsInfo = (params: ParamsProps) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await setProductInfo(params, '');
      const data = await response.data;
      dispatch({type: GET_PRODUCT_INFO, data: data});
    } catch (e: any) {}
  };
};
