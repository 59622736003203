import {Helmet} from 'react-helmet';
import {useSelector} from 'react-redux';
import {RootState} from '../store/store';
import {FC} from 'react';
interface SeoProps {
  title: string;
  description: string;
}
const SEO: FC<SeoProps> = ({title, description}) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name='description' content={description} />
      <meta property='og:type' content={'article'} />
      <meta property='og:title' content={title} />
      <meta property='og:description' content={description} />
      {/* End Facebook tags */}
      {/* Twitter tags */}
      <meta name='twitter:creator' content={'article'} />
      {/* <meta name='twitter:card' content={'type'} /> */}
      <meta name='twitter:title' content={title} />
      <meta name='twitter:description' content={description} />
    </Helmet>
  );
};

export default SEO;
