'use client';
import Header from '../../components/Header/Header';
import './Solutions.scss';
import Card from '../../components/Benefits/Card/Card';
import CardAbout from '../../components/CardAbout/CardAbout';
import ContactInfo from '../../components/ContactInfo/ContactInfo';
import MobileHeader from '../../components/MobileHeader/MobileHeader';
import useResponsiveHeader from '../../components/useResponsiveHeader/useResponsiveHeader';
import bg from '../../asserts/cctv-security.png';
import img from '../../asserts//friends-knock-hand.png';
import {useEffect} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {AppDispatch, RootState} from '../../store/store';
import ParamParser from '../../helper/ParamParser';
import {useDispatch} from 'react-redux';
import {getSolutionsInfo} from '../../store/page-solutions/actions';
import Contacts from '../../components/Contacts/Contacts';
import SEO from '../../SEO/SEO';

const Solutions = () => {
  const parserParam = ParamParser();

  const {pathname} = useLocation();

  useEffect(() => {
    document?.querySelector('body')?.scrollTo(0, 0);
  }, [pathname]);
  const {slug} = useParams();
  const dispatch: AppDispatch = useDispatch();
  const params = useSelector((state: RootState) => state.language.params);
  const info = useSelector((state: RootState) => state.solutions_page);
  const {advantages, detail_cards} = info;
  // useEffect(() => {
  //   dispatch(getSolutionsInfo(params, slug as string));
  // }, []);
  useEffect(() => {
    dispatch(getSolutionsInfo(params, slug as string));
  }, [params]);
  const {isMobileHeader, isSmallScreen, showHeader, handleClick} =
    useResponsiveHeader();
  return (
    <div className='sol_page'>
      <SEO title={info?.title} description={info?.description} />
      <div className='sol_page__wrap '>
        {isSmallScreen && (
          <MobileHeader isActive={isMobileHeader} handelClick={handleClick} />
        )}
        <div
          className={`${'sol_page__header'} sol_page__header blackout`}
          style={{background: `center/cover no-repeat url(${info?.image})`}}
        >
          <div className='wrap  '>
            {showHeader && <Header />}
            <div className={'sol_page__box'}>
              <h1 className={` ${'sol_page__title'} h1`}>{info?.title}</h1>
              <p className={'sol_page__desc'}>
                <span>{info?.description}</span>
              </p>
            </div>
          </div>
        </div>
        <div className='bg-dark padding-space'>
          <div className={`${'sol_page__row'} wrap`}>
            {advantages?.map((item: any, index: any) => {
              return (
                <div key={index} className={'sol_page__card'}>
                  <Card item={item} />
                </div>
              );
            })}
          </div>
        </div>
        {detail_cards?.map((item: any, i: any) => (
          <div className='padding-space' key={i}>
            <CardAbout
              odd={i % 2 !== 0 ? true : false}
              src={item.image}
              title={item.title}
              desc={item.description}
              withButton={item?.button_text ? true : false}
              button_title={item?.button_text}
              url={item?.button_url}
            />
          </div>
        ))}

        <div className='bg-dark '>
          <div className='wrap'>
            <div className={'sol_page__banner'}>
              <div
                className={'sol_page__img'}
                style={{
                  background: `no-repeat url(${info?.second_block_image})`,
                }}
              ></div>
              <div className={'sol_page__info'}>
                <h2 className='h2 '>{info?.second_block_title}</h2>
                <p className={'sol_page__text'}>
                  <span>{info?.second_block_subtitle}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='wrap'>
          <div className={`${'sol_page__contact'} padding-space`}>
            <Contacts />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Solutions;
