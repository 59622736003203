'use client';
import {useEffect, useRef, useState} from 'react';
import ChatBotCard from '../../components/ChatBotCard/ChatBotCard';
import style from './chatbot.module.scss';
import ChatMessage from '../../components/ChatMessage/ChatMessage';
import {Link, useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {RootState} from '../../store/store';
import ParamParser from '../../helper/ParamParser';

const ChatBot = () => {
  const parserParam = ParamParser();
  const {pathname} = useLocation();

  useEffect(() => {
    document?.querySelector('body')?.scrollTo(0, 0);
  }, [pathname]);
  const [getMessage, setGetMessage] = useState(false);
  const [currentValue, setCurrentValue] = useState('');
  const [emptyInput, setEmptyInput] = useState<boolean>(true);
  const [isOnSubmit, setIsOnSubmit] = useState(false);

  const refInput = useRef<HTMLInputElement | null>(null);

  const chatBot = useSelector(
    (state: RootState) => state.language.currentLanguage.chatBot,
  );

  useEffect(() => {
    if (!isOnSubmit) return;

    const timer = setTimeout(() => {
      setGetMessage(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, [isOnSubmit]);

  const handelSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!refInput.current) return;
    refInput.current.value = '';
    setEmptyInput(true);
    setIsOnSubmit(true);
  };
  const handelChange = (e: React.FormEvent<HTMLInputElement>) => {
    if (e.currentTarget.value.length >= 1) {
      setEmptyInput(false);
      setCurrentValue(e.currentTarget.value);
      return;
    }

    setEmptyInput(true);
  };
  const state = useSelector(
    (state: RootState) => state.language.selectedLanguage,
  );
  return (
    <div className={style.chat_bot}>
      <div className={style.chat_bot__main}>
        <div className={style.chat_bot__header}>
          <div className={`${style.chat_bot__flex} wrap`}>
            <Link to={`/${state}`} className={style.chat_bot__logo}></Link>
            <Link
              to={`/${state}/contacts`}
              className={style.chat_bot__close}
            ></Link>
          </div>
        </div>
        <div>
          {!isOnSubmit ? (
            <div
              className={`${style.chat_bot__wrap} ${style.chat_bot__scroll} wrap `}
            >
              <div className={style.chat_bot__greeting}>
                <div className={style.chat_bot__info}>
                  <h2 className={`${style.chat_bot__title} h2 `}>
                    {chatBot.title}
                  </h2>
                  <p className={style.chat_bot__desc}>
                    {chatBot.desc.map((text: any, i: any) => (
                      <span key={i}>{text}</span>
                    ))}
                  </p>
                </div>
                <div className={style.chat_bot__img}></div>
              </div>
              <div className={style.chat_bot__row}>
                {chatBot.CHATBOT_CARDS_DF.map((card: any, i: any) => {
                  return (
                    <div key={i} className={style.chat_bot__card}>
                      <ChatBotCard info={card} />
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <div>
              <ChatMessage isUser={true} desc={currentValue} />
              {getMessage && (
                <ChatMessage isUser={false} desc={chatBot.chatMessage} />
              )}
            </div>
          )}
          <div className={`${style.chat_bot__wrap} wrap `}>
            <form className={`${style.chat_bot__box} `} onSubmit={handelSubmit}>
              <input
                ref={refInput}
                onChange={handelChange}
                className={style.chat_bot__input}
                type='text'
                placeholder={chatBot.placeholder}
              />
              <button
                type='submit'
                className={style.chat_bot__button}
                disabled={emptyInput ? true : false}
              ></button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatBot;
