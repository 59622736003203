import React, {FC} from 'react';

import './CardAbout.scss';
import {CardAboutT} from '../../types/types';
import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {RootState} from '../../store/store';
import {useInView} from 'react-intersection-observer';

const CardAbout: FC<CardAboutT> = ({
  odd,
  src,
  title,
  desc,
  withButton,
  button_title,
  url,
}) => {
  const lang = useSelector(
    (state: RootState) => state.language.selectedLanguage,
  );
  const state = useSelector(
    (state: RootState) => state.language.currentLanguage,
  );
  const {ref, inView} = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });
  return (
    <div className={`about-card ${odd ? 'active' : 'none'}`}>
      <div className='about-card__wrap wrap'>
        <div className='about-card__row'>
          <div
            className='about-card__img'
            style={{background: `center/cover no-repeat url(${src})`}}
          ></div>
          <div className='about-card__info '>
            <h2 className='h2 about-card__title'>{title} </h2>
            <p className='about-card__desc'>
              <span>{desc}</span>
            </p>
            {withButton && (
              <Link
                to={`/${lang}/${url?.toLocaleLowerCase()}`}
                className='about-card__button button-orange'
              >
                {button_title}
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardAbout;
