import React, {FC, useState} from 'react';
import './MobileHeader.scss';
import {useSelector} from 'react-redux';
import {RootState} from '../../store/store';
import SelectLanguage from '../SelectLanguage/SelectLanguage';

type MobileHeaderT = {
  isActive: boolean;
  handelClick: () => void;
};
const MobileHeader: FC<MobileHeaderT> = ({isActive, handelClick}) => {
  const state = useSelector(
    (state: RootState) => state.language.selectedLanguage,
  );
  return (
    <div className={`  ${isActive ? 'active' : ''}  mobile-header`}>
      <div className={`  ${isActive ? 'active' : ''} mobile-header__wrap`}>
        {isActive ? (
          <SelectLanguage />
        ) : (
          <a href={`/${state}`} className='mobile-header__logo'></a>
        )}
        <button
          onClick={handelClick}
          className={`mobile-header__button ${isActive ? 'active' : ''}`}
        ></button>
      </div>
    </div>
  );
};

export default MobileHeader;
