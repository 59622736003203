import React, {FC} from 'react';
import './ClientCard.scss';
import {ClientCardType} from '../../constants/type';

const ClientCard: FC<ClientCardType> = ({card}) => {
  return (
    <div className='client-card'>
      <div className='client-card__inner'>
        <p className='client-card__desc'>{card.card_description}</p>
        <div className='client-card__row'>
          <p className='client-card__name'>{card.client_name}</p>
          <div
            className='client-card__logo'
            style={{
              background: `right/contain no-repeat url(${card?.client_image})`,
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default ClientCard;
