import {GET_MAIN_INFO} from './actions';
import {DataHeaderProp} from './type';

export type MainProps = {
  type: typeof GET_MAIN_INFO;
  data: DataHeaderProp;
};
export type ActionsType = MainProps;

const initialState = {};

export const MainInfoReducer = (state = initialState, action: ActionsType) => {
  switch (action.type) {
    case GET_MAIN_INFO: {
      return action.data;
    }
    default:
      return state;
  }
};
