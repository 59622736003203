import React, {FC} from 'react';
import './ChatMessage.scss';

type ChatMessageT = {
  isUser: boolean;
  desc: string;
};
const ChatMessage: FC<ChatMessageT> = ({isUser, desc}) => {
  return (
    <div className='chat-message'>
      <div className={`${isUser ? '' : '_admin'} chat-message__wrap`}>
        <div className={`chat-message__box wrap ${isUser ? '' : '_admin'}`}>
          <div
            className={`${
              isUser ? ' chat-message__img_user' : ' chat-message__img_bot'
            } chat-message__img`}
          ></div>
          <p className='chat-message__desc'>{desc}</p>
        </div>
      </div>
    </div>
  );
};

export default ChatMessage;
