import {ParamsProps} from '../../API/get-params';
import {setSolutionsInfo} from '../../API/API';
import {AppDispatch} from '../store';

export const GET_SOLUTIONS_INFO = 'page-solutions/GET_SOLUTIONS_INFO';

export type SolutionsPageProp = {
  type: typeof GET_SOLUTIONS_INFO;
  data: any;
};
export type ActionsType = SolutionsPageProp;

export const getSolutionsInfo = (params: ParamsProps, slug: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await setSolutionsInfo(params, slug);
      const data = await response.data;
      dispatch({type: GET_SOLUTIONS_INFO, data: data});
    } catch (e: any) {}
  };
};
