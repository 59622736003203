import {ParamsProps} from '../../API/get-params';
import {setProductInfo} from '../../API/API';
import {AppDispatch} from '../store';

export const GET_PRODUCT_INFO_DESC = 'page-product-desc/GET_PRODUCT_INFO';

export type ProductPageProp = {
  type: typeof GET_PRODUCT_INFO_DESC;
  data: any;
};
export type ActionsType = ProductPageProp;

export const getProductInfo = (params: ParamsProps, slug: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await setProductInfo(params, slug);
      const data = await response.data;
      dispatch({type: GET_PRODUCT_INFO_DESC, data: data});
    } catch (e: any) {}
  };
};
