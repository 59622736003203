import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import {setLanguages} from '../store/languages/actions';
import {AppDispatch} from '../store/store';
import {useEffect} from 'react';

const ParamParser = () => {
  const dispatch: AppDispatch = useDispatch();
  const {lang} = useParams<{lang: string}>();
  const params = useParams();
  const pathLang = location.pathname.split('/')[1];
  useEffect(() => {
    if (pathLang === 'he' && lang == 'he') {
      localStorage.setItem(
        'language',
        JSON.stringify({
          current: 'עב',
          optional: 'EN',
        }),
      );
      document.body.setAttribute('data-lang', 'he');
      dispatch(setLanguages('he'));
      return;
    }
    if (pathLang === 'en' && lang === 'en') {
      localStorage.setItem(
        'language',
        JSON.stringify({
          current: 'EN',
          optional: 'עב',
        }),
      );
      document.body.setAttribute('data-lang', 'en');
      dispatch(setLanguages('en'));
      return;
    } else {
      return;
    }
  }, [lang, dispatch]);

  return null;
};

export default ParamParser;
