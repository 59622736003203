import React, {FC} from 'react';
import './Preview.scss';
import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {RootState} from '../../store/store';
import {statSync} from 'fs';

const Preview: FC = () => {
  const preview = useSelector(
    (state: RootState) => state.language.currentLanguage.preview,
  );
  const lang = useSelector(
    (state: RootState) => state.language.selectedLanguage,
  );
  const info = useSelector((state: RootState) => state.main_page.main_page);
  return (
    <div className='wrap'>
      <div className='preview__wrap '>
        <h1 className='h1 preview__title'>{info?.title}</h1>
        <p className='preview__desc'>{info?.description}</p>
        <Link
          to={`/${lang}/products`}
          className='preview__button button-orange'
        >
          {info?.button_title}
        </Link>
      </div>
    </div>
    // </div>
  );
};

export default Preview;
