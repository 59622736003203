import {GET_FOOTER} from './actions';
import {DataFooterProp, DataProps} from './type';
export type FooterProp = {
  type: typeof GET_FOOTER;
  data: DataFooterProp;
};
export type ActionsType = FooterProp;

const initialState: DataProps = {
  image: '',
  address: '',
  navigation: [
    {
      name: '',
      slug: '',
    },
  ],
};

export const FooterReducer = (state = initialState, action: ActionsType) => {
  switch (action.type) {
    case GET_FOOTER: {
      return action.data;
    }
    default:
      return state;
  }
};
