import {GET_HEADER} from './actions';
import {DataHeaderProp} from './type';
export type HeaderProp = {
  type: typeof GET_HEADER;
  data: DataHeaderProp;
};
export type ActionsType = HeaderProp;

const initialState = [
  {
    image: '',
    button: [{title: '', slug: ''}],
  },
];

export const HeaderReducer = (state = initialState, action: ActionsType) => {
  switch (action.type) {
    case GET_HEADER: {
      return action.data;
    }
    default:
      return state;
  }
};
