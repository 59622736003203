import React, {useEffect, useState} from 'react';
import './styles/normalize.css';
import './styles/variables.css';
import './App.scss';
import {Routes, Route, useNavigate} from 'react-router-dom';
import MainPage from './pages/MainPage/MainPage';
import AboutPage from './pages/AboutPage/AboutPage';
import Footer from './components/Footer/Footer';
import ProductDescriptionPage from './pages/ProductDescriptionPage/ProductDescriptionPage';
import ProductsPage from './pages/ProductsPage/ProductsPage';
import NewCases from './pages/new-case/NewCases';
import Solutions from './pages/Solutions/Solutions';
import ChatBot from './pages/chatbot/page';
import Contacts from './pages/ContactsPage/ContactsPage';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from './store/store';
import {getHeader} from './store/getHeader/actions';
import {getContact} from './store/contact/actions';
import {Helmet} from 'react-helmet-async';
import {HelmetProvider} from 'react-helmet-async';
import {getMetaData} from './store/metadata/actions';
const helmetContext = {};
function App() {
  const navigate = useNavigate();

  const dispatch: AppDispatch = useDispatch();
  const params = useSelector((state: RootState) => state.language.params);
  // useEffect(() => {
  //   dispatch(getContact(params));
  //   dispatch(getHeader(params));
  //   dispatch(getMetaData(params));
  // }, []);
  useEffect(() => {
    dispatch(getHeader(params));
    dispatch(getContact(params));
    dispatch(getMetaData(params));
  }, [params]);

  useEffect(() => {
    const currentUrl = window.location.pathname;
    let currentLang = localStorage.getItem('language');
    let langParse = JSON.parse(currentLang as string);
    if (currentUrl.length <= 3) {
      if (!langParse) {
        navigate(`/en`);
      } else {
        navigate(
          `/${langParse.current.toLocaleLowerCase() === 'en' ? 'en' : 'he'}`,
        );
      }
    }
  }, []);

  const seo = useSelector((state: RootState) => state.metadata);
  const {favicon} = seo;
  return (
    <HelmetProvider context={helmetContext}>
      <Helmet>
        <link rel='icon' href={favicon} />
      </Helmet>
      <div className='App'>
        <Routes>
          <Route path='/:lang' element={<MainPage />} />
          <Route path='/:lang' element={<MainPage />} />

          <Route path='/:lang/about' element={<AboutPage />} />
          <Route path='/:lang/products' element={<ProductsPage />} />
          <Route
            path='/:lang/products/:slug'
            element={<ProductDescriptionPage />}
          />
          <Route path='/:lang/contacts' element={<Contacts />} />
          <Route path='/:lang/chatbot' element={<ChatBot />} />
          <Route path='/:lang/new-case' element={<NewCases />} />
          <Route path='/:lang/solutions/:slug' element={<Solutions />} />
        </Routes>
        <div className='padding-space-footer bg-dark'>
          <Footer />
        </div>
      </div>
    </HelmetProvider>
  );
}

export default App;
