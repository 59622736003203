import React, {FC} from 'react';
import './Card.scss';
import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {RootState} from '../../../store/store';

export type CardItemType = {
  slug: string;
  card_image: string;
  card_subtitle: string;
  card_title: string;
  card_description: string;
  card_link_text: string;
};
interface CardI {
  item: CardItemType;
}

const Card: FC<CardI> = ({item}) => {
  const state = useSelector(
    (state: RootState) => state.language.selectedLanguage,
  );
  return (
    <Link to={`/${state}/solutions/${item.slug}`} className='card'>
      <div
        className='card__img'
        style={{background: `center/cover no-repeat url(${item.card_image})`}}
      ></div>
      <div className='card__info'>
        <p className='card__label'>{item.card_subtitle}</p>
        <h2 className='h2 card__title'>{item.card_title}</h2>
        <p className='card__desc'>{item.card_description}</p>
        <p className='card__link '>{item.card_link_text}</p>
      </div>
    </Link>
  );
};
export default Card;
