import {useSelector} from 'react-redux';
import {Link, NavLink, useLocation} from 'react-router-dom';
import {RootState} from '../../store/store';
import {useState} from 'react';

export type NavLinkProps = {
  label: string;
  link: string;
};
type Props = {
  navLink: Array<NavLinkProps>;
};

export default function Navigation({navLink}: Props) {
  const state = useSelector(
    (state: RootState) => state.language.selectedLanguage,
  );
  const location = useLocation();
  const [activeIndex, setActiveIndex] = useState(-1);

  const handleLinkClick = (index: any) => {
    setActiveIndex(index);
  };
  const header = useSelector((state: RootState) => state.header);

  return (
    <>
      {header?.button?.map((item: any, index: any) => (
        <li key={index}>
          <NavLink
            to={`/${state}${
              item?.slug ? `/` + item?.slug.toLocaleLowerCase() : ''
            }`}
            className={`header__link ${
              location.pathname.endsWith(item.title) ? '' : 'none  '
            }`}
            onClick={() => handleLinkClick(index)}
          >
            {item.title}
          </NavLink>
        </li>
      ))}
    </>
  );
}
