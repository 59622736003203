import {params_en, params_he} from '../../API/get-params';
import {df_info_en} from '../../constants/constants_en';
import {df_info_he} from './../../constants/constants_he';

import {SET_LANGUAGE, SetLanguageAction} from './actions';

let lang = localStorage.getItem('language') as string;

const initialState = {
  params: params_en,
  isCurrent: JSON.parse(lang)?.current === 'en' ? true : false,
  selectedLanguage: JSON.parse(lang)?.current || 'en',
  currentLanguage: JSON.parse(lang)?.current === 'en' ? df_info_en : df_info_he,
};

export const languageReducer = (
  state = initialState,
  action: SetLanguageAction,
) => {
  switch (action.type) {
    case SET_LANGUAGE: {
      let clone = structuredClone(state);
      clone.selectedLanguage = action.payload;
      clone.selectedLanguage === 'en'
        ? ((clone.currentLanguage = df_info_en),
          (clone.params = params_en),
          (clone.isCurrent = true))
        : ((clone.currentLanguage = df_info_he),
          (clone.params = params_he),
          (clone.isCurrent = false));
      return clone;
    }
    default:
      return state;
  }
};
