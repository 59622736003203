import {FC, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {RootState} from '../../store/store';

interface LogoProps {
  className?: string;
}

const Logo: FC<LogoProps> = ({className}) => {
  const state = useSelector(
    (state: RootState) => state.language.selectedLanguage,
  );
  const logo = useSelector((state: RootState) => state?.header?.logo);

  return (
    <>
      {logo && (
        <Link
          to={`/${state}`}
          style={{background: ` center/contain no-repeat url(${logo}`}}
          className={`${className} logo`}
        ></Link>
      )}
    </>
  );
};

export default Logo;
