import React, {FC, useCallback, useEffect} from 'react';
import CardAbout from '../../components/CardAbout/CardAbout';
import Header from '../../components/Header/Header';
import './AboutPage.scss';
import MobileHeader from '../../components/MobileHeader/MobileHeader';
import useResponsiveHeader from '../../components/useResponsiveHeader/useResponsiveHeader';
import Contacts from '../../components/Contacts/Contacts';
import {Link, useLocation, useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {AppDispatch, RootState} from '../../store/store';
import ParamParser from '../../helper/ParamParser';
import {useDispatch} from 'react-redux';
import {getAboutInfo} from '../../store/page-about/actions';
import SEO from '../../SEO/SEO';
import {useInView} from 'react-intersection-observer';

const About: FC = () => {
  const parserParam = ParamParser();
  const {isMobileHeader, isSmallScreen, showHeader, handleClick} =
    useResponsiveHeader();
  const {pathname} = useLocation();

  const lang = useSelector(
    (state: RootState) => state.language.selectedLanguage,
  );
  const params = useSelector((state: RootState) => state.language.params);
  useEffect(() => {
    document?.querySelector('body')?.scrollTo(0, 0);
  }, [pathname]);
  const about_info = useSelector((state: RootState) => state.about_page);
  const {advantages, technologies, customers} = about_info;

  const dispatch: AppDispatch = useDispatch();

  const fetchAboutInfo = useCallback(() => {
    dispatch(getAboutInfo(params));
  }, [dispatch, params]);

  useEffect(() => {
    fetchAboutInfo();
  }, [fetchAboutInfo]);
  const {ref: cardRef, inView: cardInView} = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });
  const {ref: technologiesRef, inView: technologiesInView} = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });
  const {ref: customersRef, inView: customersInView} = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });
  const {ref: contactsRef, inView: contactsInView} = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });
  let count = 0;
  return (
    <div className='about-page'>
      <SEO
        title={about_info?.main?.title}
        description={about_info?.main?.description}
      />
      {isSmallScreen && (
        <MobileHeader isActive={isMobileHeader} handelClick={handleClick} />
      )}
      <div
        className={`about-page__header blackout`}
        style={{
          background: `center/cover no-repeat url(${about_info?.main?.image})`,
        }}
      >
        {showHeader && <Header />}
        <div className={`about-page__info wrap`}>
          <h1 className={`about-page__title h1`}>{about_info?.main?.title}</h1>
          <p className='about-page__desc'>{about_info?.main?.description}</p>
          <Link
            to={`/${lang}/${about_info?.main?.button_url.toLowerCase()}`}
            className={`about-page__button  button-orange`}
          >
            {about_info?.main?.button && about_info?.main?.button}
          </Link>
        </div>
      </div>

      <div>
        {advantages?.map((item: any, index: any) => {
          count++;
          return (
            <div key={index}>
              <div className={`padding-space-top about-page__products`}>
                <CardAbout
                  odd={(index + 1) % 2 !== 0 ? false : true}
                  src={item.image}
                  title={item.title}
                  desc={item.description}
                  withButton={item?.button_text && true}
                  button_title={item?.button_text}
                  url={item?.button_url}
                />
              </div>
              {count === 2 && (
                <div
                  className='padding-space-top _banner'
                  ref={technologiesRef}
                >
                  {technologiesInView && (
                    <div
                      className={`about-page__banner blackout`}
                      style={{
                        background: `center/cover no-repeat url(${technologies?.image})`,
                      }}
                    >
                      <div className='wrap'>
                        <p>{technologies?.title}</p>
                        <span>{technologies?.description}</span>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {count === 3 && (
                <div className='padding-space-top' ref={customersRef}>
                  {customersInView && (
                    <div className={`padding-space-top bg-dark`}>
                      <div className='about-page__box_desc'>
                        <div className='wrap'>
                          <h2 className='h2'> {customers?.title}</h2>
                          <span>{customers?.description}</span>
                          <Link
                            to={`/${lang}/${customers?.button_url.toLowerCase()}`}
                            className='button-orange'
                          >
                            {customers?.button_text}
                          </Link>
                        </div>
                        <div className='about-page__bg'></div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>

      <div className='padding-space bg-light' ref={contactsRef}>
        {contactsInView && (
          <div className='wrap'>
            <Contacts />
          </div>
        )}
      </div>
    </div>
  );
};

export default About;
