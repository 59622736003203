import {ActionsType, GET_PRODUCT_INFO_DESC} from './actions';

const initialState = {};

export const ProductDescInfoReducer = (
  state = initialState,
  action: ActionsType,
) => {
  switch (action.type) {
    case GET_PRODUCT_INFO_DESC: {
      return action.data;
    }
    default:
      return state;
  }
};
