import {ParamsProps} from './../../API/get-params';
import {setMainInfo} from '../../API/API';
import {AppDispatch} from '../store';

export const GET_META_DATA = 'metadata/GET_META_DATA';

export type MetaDataProp = {
  type: typeof GET_META_DATA;
  data: any;
};
export type ActionsType = MetaDataProp;

export const getMetaData = (params: ParamsProps) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await setMainInfo(params, 'meta');
      const data = await response.data;
      dispatch({type: GET_META_DATA, data: data});
    } catch (e: any) {}
  };
};
