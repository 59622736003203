import React, {FC} from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {RootState} from '../../store/store';
import {AboutUsType} from '../../constants/type';

type Props = {
  items: Array<AboutUsType>;
};

const AboutUsLinks: FC<Props> = ({items}) => {
  const state = useSelector(
    (state: RootState) => state.language.selectedLanguage,
  );
  const info = useSelector(
    (state: RootState) => state.main_page.continuous_innovation?.innovation,
  );

  return (
    <>
      {info?.map((item: any, index: any) => {
        return (
          <div className='about-us__inner' key={index}>
            <div className='about-us__box'>
              {/* <span className='about-us__label'>{item.label}</span> */}
              <p className='about-us__text large-font '>{item.card_title}</p>
              <Link
                className='about-us__link link-more-light'
                to={`/${state}/${item?.url ? item?.url : 'about'}`}
              >
                {item.link_text}
              </Link>
            </div>
            <span className='about-us__line'></span>
          </div>
        );
      })}
    </>
  );
};

export default AboutUsLinks;
