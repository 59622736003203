import {ParamsProps} from './../../API/get-params';
import {setMainInfo} from '../../API/API';
import {AppDispatch} from '../store';

export const GET_CONTACT = 'contact/GET_CONTACT';

export type HeaderProp = {
  type: typeof GET_CONTACT;
  data: any;
};
export type ActionsType = HeaderProp;

export const getContact = (params: ParamsProps) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await setMainInfo(params, 'lets-talk');
      const data = await response.data;
      dispatch({type: GET_CONTACT, data: data});
    } catch (e: any) {}
  };
};
