import React, {FC} from 'react';
import './Contacts.scss';
import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {RootState} from '../../store/store';
import {useInView} from 'react-intersection-observer';

const Contacts: FC = () => {
  const contacts = useSelector(
    (state: RootState) => state.language.currentLanguage.contacts,
  );
  const {ref, inView} = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });
  const lang = useSelector(
    (state: RootState) => state.language.selectedLanguage,
  );
  const info = useSelector((state: RootState) => state?.contact);

  return (
    <div className='contacts' ref={ref}>
      {inView && (
        <div className='contacts__wrap wrap'>
          <h2 className='h2 contacts__title'>{info?.title}</h2>
          <p className='contacts__desc'>{info?.text}</p>
          <Link
            to={`/${lang}/${info?.button_url?.toLocaleLowerCase()}`}
            className='contacts__button button-orange'
          >
            {info?.button_text}
          </Link>
        </div>
      )}
    </div>
  );
};

export default Contacts;
